import React, { useContext } from 'react'
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { Formik } from "formik";
import * as yup from "yup";
import axios from 'axios';
import { UserContext } from '../../context/UserContext';

function NotificationDialog({ open, handleClose }) {
    const { token } = useContext(UserContext);

    var initialValues = {
        content: "",
        type: "general"
    };

    var validationObject = {
        content: yup.string().required("zorunlu"),
        type: yup.string().required("zorunlu")
    }

    const notificationSchema = yup.object().shape(validationObject);


    const handleFormSubmit = (values) => {
        axios.post(`${process.env.REACT_APP_API_URL}api/alcago/notification/notifyUsers?token=${token}`, {
            notification: {
                ...values
            }
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                handleClose();
            }
        });
    }

    return (
        <Dialog sx={{ margin: "0 auto" }}
            open={open}
            fullWidth
            onClose={() => handleClose()}>
            <Box minWidth="300px" m="20px" textAlign="center">
                <Typography variant='h3'>
                    Tüm Kullanıcılara Bildirim Gönder
                </Typography>
            </Box>

            <DialogContent>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={notificationSchema}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="25px" mb="25px">
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Bildirim İçeriği"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name={"content"}
                                    multiline
                                    minRows={4}
                                    value={values.content}
                                    error={!!touched.content && !!errors.content}
                                    helperText={touched.content && errors.content}
                                    sx={{ gridColumn: `span 2` }}
                                />
                            </Box>
                            <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                <Button type="submit" color="secondary" variant="contained">
                                    <Typography variant='h5'>Gönder</Typography>
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog >
    )
}

export default NotificationDialog;