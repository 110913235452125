import { Box, Button, Checkbox, Typography, useTheme } from '@mui/material'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import React from 'react'
import { tokens } from '../theme';
import { useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import VideoPlayerWithThumbnail from './VideoPlayerWithThumbnail';

function AdBoxForAdmin({ ad, handleAdSelection }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const navigate = useNavigate();

    const [featured, setFeatured] = useState(ad.is_featured === 1);
    const [featuredWalletPage, setFeaturedWalletPage] = useState(ad.is_featured_wallet_page === 1);
    const [featuredSavedAdsPage, setFeaturedSavedAdsPage] = useState(ad.is_featured_saved_ads_page === 1);
    const [hover, setHover] = useState(false);

    const handleFeatured = (adModel) => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/ad/updateAd?token=${token}&adId=${ad.id}`, {
            ad: {
                ...adModel
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (adModel.is_featured !== undefined && adModel.is_featured !== null) {
                        setFeatured((prev) => !prev);
                    } else if (adModel.is_featured_wallet_page !== undefined && adModel.is_featured_wallet_page !== null) {
                        setFeaturedWalletPage((prev) => !prev);
                    } else if (adModel.is_featured_saved_ads_page !== undefined && adModel.is_featured_saved_ads_page !== null) {
                        setFeaturedSavedAdsPage((prev) => !prev);
                    }
                }
            });
    }

    return (
        <Box key={ad.id}
            width="23%"
            display="inline-block"
            m="10px"
            sx={{ background: colors.grey[100], border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <VideoPlayerWithThumbnail thumbnailLink={ad.thumbnail_image_link} videoLink={ad.video_link} />
            <Box pb="10px"
                sx={hover ? {
                    backgroundColor: theme.palette.mode === 'dark' ? colors.grey[100] : "white",
                    opacity: [0.9, 0.8, 0.7, 0.6, 0.5],
                    cursor: "pointer",
                    borderRadius: "0 0 20px 20px"
                } : {}}>

                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => handleAdSelection(ad)}
                    onMouseEnter={() => { setHover(true) }}
                    onMouseLeave={() => { setHover(false) }}>
                    <Box ml="20px" pt="10px" pb="10px" >
                        <Typography variant='h3' color={colors.primary[900]}>{ad.brand_name}</Typography>
                        <Typography variant='h6' color={colors.primary[700]}>{ad.has_coupons === 1 ? `Son Geçerlilik: ${ad.last_usage_date.split("T")[0]}` : "Bu reklam kupon dağıtmamaktadır."}</Typography>
                        <Typography variant='h6' color={colors.primary[700]}>{ad.has_coupons === 1 ? `Kupon İndirim Yüzdesi: ${ad.discount_percentage}` : <br />}</Typography>
                        <Typography variant='h6' color={colors.primary[700]}>{ad.has_coupons === 1 ? `Sağlanan kupon sayısı: ${ad.starting_coupon_count}` : <br />}</Typography>
                        <Typography variant='h6' color={colors.primary[700]}>{ad.has_coupons === 1 ? `Kalan kupon sayısı: ${ad.remaining_coupon_count}` : <br />}</Typography>
                    </Box>
                    <Box>
                        <Box height="15%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                            p="5px 10px 5px 10px"
                            mr="10px"
                            mb="20px"
                            zIndex="10"
                            sx={{ background: colors.greenAccent[700], borderRadius: "20px" }}>
                            <RemoveRedEyeOutlinedIcon sx={{ color: colors.grey[100] }} />
                            <Typography variant='h6' color={colors.grey[100]} ml="5px">{ad.watch_count}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box mx={"10px"}>
                    <Box>
                        <Checkbox
                            checked={featured}
                            disabled={ad.has_coupons === 0}
                            size="medium"
                            sx={{
                                '&.Mui-checked': {
                                    color: (theme.palette.mode === "dark" ? colors.greenAccent[700] : colors.greenAccent[500])
                                },
                                '&.MuiCheckbox-root': {
                                    color: (ad.has_coupons === 0 ? colors.grey[300] : theme.palette.mode === "dark" ? colors.greenAccent[700] : colors.greenAccent[500])
                                }
                            }}
                            onClick={(event) => {
                                handleFeatured({ is_featured: !featured });
                            }}
                        />
                        <Typography color={ad.has_coupons === 0 ? colors.grey[300] : colors.primary[700]} display="inline">Öne Çıkarılmış (Ana Sayfa)</Typography>
                    </Box>
                    <Box>
                        <Checkbox
                            checked={featuredWalletPage}
                            disabled={ad.has_coupons === 0}
                            size="medium"
                            ml="20px"
                            sx={{
                                '&.Mui-checked': {
                                    color: (theme.palette.mode === "dark" ? colors.greenAccent[700] : colors.greenAccent[500])
                                },
                                '&.MuiCheckbox-root': {
                                    color: (ad.has_coupons === 0 ? colors.grey[300] : theme.palette.mode === "dark" ? colors.greenAccent[700] : colors.greenAccent[500])
                                }
                            }}
                            onClick={(event) => {
                                handleFeatured({ is_featured_wallet_page: !featuredWalletPage });
                            }}
                        />
                        <Typography color={ad.has_coupons === 0 ? colors.grey[300] : colors.primary[700]} display="inline">Öne Çıkarılmış (Cüzdan Sayfası)</Typography>
                    </Box>
                    <Box>
                        <Checkbox
                            checked={featuredSavedAdsPage}
                            disabled={ad.has_coupons === 0}
                            size="medium"
                            ml="20px"
                            sx={{
                                '&.Mui-checked': {
                                    color: (theme.palette.mode === "dark" ? colors.greenAccent[700] : colors.greenAccent[500])
                                },
                                '&.MuiCheckbox-root': {
                                    color: (ad.has_coupons === 0 ? colors.grey[300] : theme.palette.mode === "dark" ? colors.greenAccent[700] : colors.greenAccent[500])
                                }
                            }}
                            onClick={(event) => {
                                handleFeatured({ is_featured_saved_ads_page: !featuredSavedAdsPage });
                            }}
                        />
                        <Typography color={ad.has_coupons === 0 ? colors.grey[300] : colors.primary[700]} display="inline">Öne Çıkarılmış (Kayıtlı Reklamlar Sayfası)</Typography>
                    </Box>
                </Box>
                <Box mt="10px" textAlign="center">
                    <Button variant='outlined' color='warning' onClick={() => navigate(`/admin-panel/coupon-details/${ad.id}`)}>
                        Reklam Detayını Gör
                    </Button>
                </Box>

            </Box>

        </Box>
    )
}

export default AdBoxForAdmin