import { Box, IconButton, InputAdornment, TextField, Tooltip, Typography, useTheme, Button, Dialog, CircularProgress, DialogContent } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfiniteScroll from 'react-infinite-scroll-component';
import AdSelectionDialog from '../../../../components/reportGeneration/adSelectionDialog';
import ReportGenerationDialog from '../../../../components/reportGeneration/reportGenerationDialog';

function GeneratedReports() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, token } = useContext(UserContext);
  const [reports, setReports] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [selectedBrand, setSelectedBrand] = useState({ brand_id: user.id });
  const [selectedAd, setSelectedAd] = useState(null);
  const [adSelectionDialogOpen, setAdSelectionDialogOpen] = useState(false);
  const [reportGenerationDialogOpen, setReportGenerationDialogOpen] = useState(false);
  const [adStatistics, setAdStatistics] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchReports(0);
  }, []);

  useEffect(() => {
    if (selectedAd) {
      setAdSelectionDialogOpen(false);
      fetchAdStatisticsToGenerateReport(selectedAd);
    }
  }, [selectedAd]);

  useEffect(() => {
    if (adStatistics) {
      if (adStatistics.report.title) {
        generateReport(adStatistics);
      } else {
        setReportGenerationDialogOpen(true);
      }
    }
  }, [adStatistics]);

  const fetchReports = (startIndex) => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/report/getReports?token=${token}&searchKey=${searchKey}&brandId=${user.id}&startIndex=${startIndex}&limit=10`)
      .catch((err) => {
        console.log("err: " + err);
        setReports([]);
        setHasMore(false);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setHasMore(response.data.reports && response.data.reports.length === 50);
          if (startIndex === 0) {
            setReports([...response.data.reports]);
          } else {
            setReports((prev) => [...prev, ...response.data.reports]);
          }
        }
      });
  }

  const fetchAdStatisticsToGenerateReport = (ad) => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAdStatisticsToGenerateReport?token=${token}&adId=${ad.ad_id}`)
      .catch((err) => {
        console.log("err: " + err);
        setSelectedAd(null);
        setLoading(false);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setSelectedAd(null);
          setLoading(false);
          setAdStatistics(getAdStatisticsModel(response.data.adStatistics));
        }
      });
  }

  const getAdStatisticsModel = (adStatistics) => {
    return {
      report: {
        ad_id: adStatistics.ad.id,
        brand_name: adStatistics.ad.brand_name,
        brand_logo_path: adStatistics.ad.brand_logo_path,
        ad_thumbnail_path: adStatistics.ad.thumbnail_image_path,
        bubble_image_path: adStatistics.ad.bubble_image_path,
        coupon_given_ad: adStatistics.ad.has_coupons,
        generated_by_alcago: false,
        is_vertical: adStatistics.ad.is_vertical,
        title: null,
        avarage_point: adStatistics.ad.avarage_point,
        ad_like_count: adStatistics.ad.ad_like_count,
        coupon_distribution_percentage: adStatistics.ad.coupon_distribution_percentage,
        conversion_percentage: adStatistics.ad.conversion_percentage,
        avarage_basket_amount: adStatistics.ad.avarage_basket_amount,
        sector_name: adStatistics.ad.sector_name,
        advices: null
      },
      comments: adStatistics.comments.map((comment) => {
        return {
          username: comment.user_name.charAt(0) + "*** " + comment.user_surname.charAt(0) + "***",
          profile_photo_path: comment.profile_photo_path,
          point: comment.point,
          comment: comment.comment
        }
      }),
      questions: adStatistics.questions.map((question) => {
        return {
          question: question.question,
          answer1: question.answer1,
          answer2: question.answer2,
          answer3: question.answer3,
          correct_answer: question.correct_answer,
          true_answer_count: question.true_answer_count,
          false_answer_count: question.false_answer_count
        }
      }),
      watchLogs: adStatistics.watchLogs.map((watchLog) => {
        return {
          city: watchLog.city,
          gender: watchLog.gender,
          marital_status: watchLog.marital_status,
          education: watchLog.education,
          income_status: watchLog.income_status,
          statistic_date: watchLog.created_date.split("Z")[0].split("T")[0]
        }
      }),
      actionLogs: adStatistics.actionLogs.map((actionLog) => {
        return {
          action_name: actionLog.type === 1 ? (actionLog.touched === 1 ? "Patlatılan Baloncuk" : "Patlatılmayan Baloncuk") : (actionLog.touched === 1 ? "Patlatılan Bomba" : "Patlatılmayan Bomba"),
          x_coordinate: actionLog.x_coordinate,
          y_coordinate: actionLog.y_coordinate,
          statistic_date: actionLog.created_date.split("Z")[0].split("T")[0]
        }
      })
    }
  }

  const generateReport = (adStatistics) => {
    axios.post(`${process.env.REACT_APP_API_URL}api/alcago/report/createReport?token=${token}`, adStatistics)
      .catch((err) => {
        console.log("err: " + err);
        setReportGenerationDialogOpen(false);
        setAdStatistics(null);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 201) {
          console.log(response.data.reportId);
          setReportGenerationDialogOpen(false);
          setAdStatistics(null);
          setReports([]);
          fetchReports(0);
        }
      });
  };

  return (
    <Box m="20px">
      {loading && <Dialog open={loading} onClose={() => { }}> <DialogContent><CircularProgress /></DialogContent> </Dialog>}
      {selectedBrand && <AdSelectionDialog open={adSelectionDialogOpen} brandId={selectedBrand ? selectedBrand.brand_id : null} handleClose={(ad) => {
        if (ad) {
          setSelectedAd(ad);
        } else {
          setSelectedAd(null);
          setAdSelectionDialogOpen(false);
        }
      }} />}
      {adStatistics && <ReportGenerationDialog open={reportGenerationDialogOpen} fromAdminPanel={false} handleClose={(reportDetails) => {
        if (reportDetails) {
          setAdStatistics({ ...adStatistics, report: { ...(adStatistics.report), ...reportDetails } });
        } else {
          setAdStatistics(null);
          setReportGenerationDialogOpen(false);
        }
      }} />}
      <Box display="flex" gap="20px" alignItems="center">
        <Box>
          <Header title="Raporlar" subtitle="Oluşturduğunuz raporları buradan görebilir ve düzenleyebilirsiniz." />
        </Box>
        <TextField
          id="outlined-basic"
          label="Rapor ara"
          variant="outlined"
          onChange={(event) => {
            setSearchKey(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setSearchKey(event.target.value);
              setReports([]);
              fetchReports(0);
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => {
                  setReports([]);
                  fetchReports(0);
                }}
                  sx={{ marginLeft: "10px" }}>
                  <SearchOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }} />
        <Button variant='contained' color='success' sx={{ margin: "auto 0" }} onClick={() => {
          setAdSelectionDialogOpen(true);
        }}>
          Rapor Oluştur
        </Button>
      </Box>
      <Box>
        <InfiniteScroll
          dataLength={reports.length}
          next={() => fetchReports(reports.length)}
          hasMore={hasMore}
          loader={<h4>Yükleniyor...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Tüm raporları görüntülüyorsunuz.</b>
            </p>
          }
        >
          {reports.map((report, i) =>
          (<Box key={i} display="inline-block" p="20px" mr="20px" mt="20px" sx={{
            background: colors.primary[400], borderRadius: "20px", width: "310px",
            '&:hover': {
              backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[700],
              opacity: [0.9, 0.8, 0.7, 0.6, 0.5],
              cursor: "pointer",
              borderRadius: "20px"
            }
          }}
            onClick={() => {
              window.open(`/reports/${report.unique_id}`, '_blank'/*, 'rel=noopener noreferrer'*/)
            }}>
            <Box textAlign="center">
              <img
                alt="report-generated-ad"
                src={report.ad_thumbnail_link}
                height="130px"
                style={{ cursor: "pointer", borderRadius: "20px" }} />
              <Tooltip title={(report.title).length >= 18 ? report.title : ""}>
                <Typography variant='h3' mt="15px">{((report.title).length >= 18 ? (report.title).slice(0, 15) + "..." : report.title)}</Typography>
              </Tooltip>
            </Box>
            <hr />
            <Box display="flex" gap="10px" alignItems="center" justifyContent="center" mt="10px">
              <Box padding="5px 10px" sx={{ background: colors.blueAccent[700], borderRadius: "10px" }}>
                <Typography variant='h6'>{report.brand_name}</Typography>
              </Box>
              {report.generated_by_alcago === 1 &&
                <Box padding="5px 10px" sx={{ background: report.generated_by_alcago === 1 ? colors.greenAccent[600] : colors.redAccent[600], borderRadius: "10px" }}>
                  <Typography variant='h6'>{report.generated_by_alcago === 1 ? "Alcago" : "Marka"}</Typography>
                </Box>
              }
              <Box padding="5px 10px" sx={{ background: colors.grey[600], borderRadius: "10px" }}>
                <Typography variant='h6' color="white">{new Date(report.created_date).toLocaleDateString()}</Typography>
              </Box>
            </Box>
          </Box>)
          )}
        </InfiniteScroll>
      </Box>
    </Box >
  )
}

export default GeneratedReports