import React, { useContext, useState, useEffect } from 'react'
import { Alert, Box, Dialog, DialogContent, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../theme';
import { UserContext } from '../../../context/UserContext';

function AdSelectionDialog({ brandId, open, handleClose, filterForWebRegistration }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.colors);
    const { token } = useContext(UserContext);
    const [showAlert, setShowAlert] = useState(false);
    const [ads, setAds] = useState([]);

    const fetchAds = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAdsWithBrandId?token=${token}&brandId=${brandId}`)
            .catch((err) => {
                console.log("err: " + err);
                setShowAlert(true);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setAds(filterForWebRegistration ? response.data.ads.filter(ad => ad.has_coupons && ad.is_active && new Date(ad.last_usage_date) >= new Date() && ad.remaining_coupon_count > 0) : response.data.ads);
                }
            });
    }

    useEffect(() => {
        fetchAds();
    }, [])


    const handleSelection = (ad) => {
        handleClose({ ad_id: ad.id });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={open}
            onClose={() => handleClose(null)}
            fullWidth={true}
            maxWidth="xl">
            <Box minWidth="300px" m="20px" textAlign="center">
                <Typography variant='h3'>
                    Reklam Seç
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    {(showAlert || ads.length < 1) &&
                        <Alert severity="error" sx={{ mt: "10px" }} onClick={() => (setShowAlert(false))}>Uygun reklam bulunamadı!</Alert>
                    }
                    <Box mt="10px">
                        {ads.map((ad) =>
                        (
                            <Box key={ad.id}
                                display="inline-block"
                                m="10px"
                                padding="20px"
                                sx={{
                                    background: colors.grey[100],
                                    border: "1px solid " + colors.grey[500],
                                    borderRadius: "20px",
                                    '&:hover': {
                                        backgroundColor: theme.palette.mode === 'dark' ? colors.grey[300] : colors.grey[700],
                                        opacity: [0.9, 0.8, 0.7, 0.6, 0.5],
                                        cursor: "pointer",
                                        borderRadius: "20px"
                                    }
                                }}
                                onClick={() => handleSelection(ad)}
                            >
                                <Box textAlign="center">
                                    <img
                                        alt="ad-logo"
                                        src={ad.thumbnail_image_link}
                                        onError={(e) => { if (!e.target.src || !e.target.src.includes("undefined")) { e.target.src = ad.brand_logo_link } }}
                                        height="130px"
                                        style={{ cursor: "pointer", borderRadius: "20px" }} />
                                    <Typography variant='h4' mt="10px" color="white">{ad.coupon_title ? ad.coupon_title : "Kuponsuz Reklam"}</Typography>
                                </Box>
                            </Box>
                        )
                        )}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AdSelectionDialog;