import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Box, Button, Dialog, DialogContent, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../../../theme';
import { UserContext } from '../../../../../context/UserContext';
import * as yup from "yup";
import { Formik, useField, useFormikContext } from "formik";

function TargetPointSettingsDialog({ open, handleClose }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.colors);
    const { token } = useContext(UserContext);
    const [targetPoints, setTargetPoints] = useState({
        favorites_point: 0,
        age_point: 0,
        gender_point: 0,
        residence_city_point: 0,
        education_point: 0,
        income_status_point: 0,
        team_point: 0,
        car_point: 0,
        marital_status_point: 0,
        hobby_point: 0,
        questions_point: 0,
    });

    const fetchTargetPoints = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/admin/getTargetPoints?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setTargetPoints(response.data.targetPoints);
                }
            });
    }

    var initialValues = {
        favorites_point: targetPoints.favorites_point,
        age_point: targetPoints.age_point,
        gender_point: targetPoints.gender_point,
        residence_city_point: targetPoints.residence_city_point,
        education_point: targetPoints.education_point,
        income_status_point: targetPoints.income_status_point,
        team_point: targetPoints.team_point,
        car_point: targetPoints.car_point,
        marital_status_point: targetPoints.marital_status_point,
        hobby_point: targetPoints.hobby_point,
        questions_point: targetPoints.questions_point
    }

    var shapeObject = {
        favorites_point: yup.number().min(0, "En az 1 olabilir").required("zorunlu"),
        age_point: yup.number().min(0, "En az 1 olabilir").required("zorunlu"),
        gender_point: yup.number().min(0, "En az 1 olabilir").required("zorunlu"),
        residence_city_point: yup.number().min(0, "En az 1 olabilir").required("zorunlu"),
        education_point: yup.number().min(0, "En az 1 olabilir").required("zorunlu"),
        income_status_point: yup.number().min(0, "En az 1 olabilir").required("zorunlu"),
        team_point: yup.number().min(0, "En az 1 olabilir").required("zorunlu"),
        car_point: yup.number().min(0, "En az 1 olabilir").required("zorunlu"),
        marital_status_point: yup.number().min(0, "En az 1 olabilir").required("zorunlu"),
        hobby_point: yup.number().min(0, "En az 1 olabilir").required("zorunlu"),
        questions_point: yup.number().min(0, "En az 1 olabilir").required("zorunlu")
    }

    const targetPointsSchema = yup.object().shape(shapeObject);

    useEffect(() => {
        fetchTargetPoints();
    }, [open]);

    const handleFormSubmit = async (values) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/admin/updateTargetPoints?token=${token}`, { targetPoints: values }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                handleClose();
            }
        });;
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={open}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    Hedefleme Ayarları
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={targetPointsSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Favoriye Ekleme Puanı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"favorites_point"}
                                        value={values.favorites_point}
                                        error={!!touched.favorites_point && !!errors.favorites_point}
                                        helperText={touched.favorites_point && errors.favorites_point}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Yaş Puanı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"age_point"}
                                        value={values.age_point}
                                        error={!!touched.age_point && !!errors.age_point}
                                        helperText={touched.age_point && errors.age_point}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Cinsiyet Puanı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"gender_point"}
                                        value={values.gender_point}
                                        error={!!touched.gender_point && !!errors.gender_point}
                                        helperText={touched.gender_point && errors.gender_point}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Yaşanılan Şehir Puanı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"residence_city_point"}
                                        value={values.residence_city_point}
                                        error={!!touched.residence_city_point && !!errors.residence_city_point}
                                        helperText={touched.residence_city_point && errors.residence_city_point}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Eğitim Durumu Puanı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"education_point"}
                                        value={values.education_point}
                                        error={!!touched.education_point && !!errors.education_point}
                                        helperText={touched.education_point && errors.education_point}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Gelir Durumu Puanı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"income_status_point"}
                                        value={values.income_status_point}
                                        error={!!touched.income_status_point && !!errors.income_status_point}
                                        helperText={touched.income_status_point && errors.income_status_point}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Tutulan Takım Puanı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"team_point"}
                                        value={values.team_point}
                                        error={!!touched.team_point && !!errors.team_point}
                                        helperText={touched.team_point && errors.team_point}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Medeni Hal Puanı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"marital_status_point"}
                                        value={values.marital_status_point}
                                        error={!!touched.marital_status_point && !!errors.marital_status_point}
                                        helperText={touched.marital_status_point && errors.marital_status_point}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Hobi Puanı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"hobby_point"}
                                        value={values.hobby_point}
                                        error={!!touched.hobby_point && !!errors.hobby_point}
                                        helperText={touched.hobby_point && errors.hobby_point}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Hedeflenebilir Soru Puanı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"questions_point"}
                                        value={values.questions_point}
                                        error={!!touched.questions_point && !!errors.questions_point}
                                        helperText={touched.questions_point && errors.questions_point}
                                        sx={{ gridColumn: "span 1" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="center" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default TargetPointSettingsDialog;