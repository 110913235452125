import React, { useContext, useEffect, useState } from 'react'
import { Alert, Box, Dialog, DialogContent, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../theme';
import { UserContext } from '../../../context/UserContext';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

function BrandSelectionDialog({ open, handleClose }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.colors);
    const { token } = useContext(UserContext);

    const [searchKey, setSearchKey] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [brands, setBrands] = useState([]);

    const fetchBrands = (searchKey) => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/getAllBrandsWithFilter?token=${token}`, {
            filter: {
                orderBy: "name",
                "orderType": "ascending",
                "searchKey": searchKey,
                "popular": false,
                "newest": false,
                "favorites": false
            }
        })
            .catch((err) => {
                console.log("err: " + err);
                setShowAlert(true);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setBrands(response.data.brands);
                }
            });
    }

    useEffect(() => {
        fetchBrands(searchKey);
    }, [searchKey])


    const handleSelection = (brand) => {
        setSearchKey("");
        handleClose({ brand_id: brand.id });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={open}
            onClose={() => {
                setSearchKey("");
                handleClose(null);
            }}
            fullWidth={true}
            maxWidth="xl">
            <Box minWidth="300px" m="20px" textAlign="center">
                <Typography variant='h3'>
                    Marka Seç
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <TextField
                        id="outlined-basic"
                        label="Search"
                        variant="outlined"
                        onChange={(event) => {
                            setSearchKey(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => fetchBrands(searchKey)}>
                                        <SearchOutlinedIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            ml: "10px"
                        }}
                    />
                    {showAlert &&
                        <Alert severity="error" sx={{ mt: "10px" }} onClick={() => (setShowAlert(false))}>Bir hata oluştu!</Alert>
                    }
                    <Box mt="10px">
                        {brands.map((brand) =>
                        (
                            <Box key={brand.id}
                                display="inline-block"
                                m="10px"
                                padding="20px"
                                sx={{
                                    background: colors.grey[100],
                                    border: "1px solid " + colors.grey[500],
                                    borderRadius: "20px",
                                    '&:hover': {
                                        backgroundColor: theme.palette.mode === 'dark' ? colors.grey[300] : colors.grey[700],
                                        opacity: [0.9, 0.8, 0.7, 0.6, 0.5],
                                        cursor: "pointer",
                                        borderRadius: "20px"
                                    }
                                }}
                                onClick={() => handleSelection(brand)}
                            >
                                <Box display="flex" alignItems="center">
                                    <img
                                        alt="brand-logo"
                                        src={brand.brand_logo_link}
                                        width="40px"
                                        height="40px"
                                        style={{ cursor: "pointer", borderRadius: "50%" }} />
                                    <Typography variant='h4' ml="10px" color="white">{brand.name}</Typography>
                                </Box>
                            </Box>
                        )
                        )}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default BrandSelectionDialog;