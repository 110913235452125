import { Rating, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import CommentBox from '../../../../components/CommentBox';
import Header from '../../../../components/Header';
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import { useEffect } from 'react';

function Comments() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user, token } = useContext(UserContext);
    const [comments, setComments] = useState([]);

    const approveComment = (commentId) => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/approveComment?token=${token}&commentId=${commentId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = comments.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        comments[ix].is_approved = 1;
                        setComments(Array.from(comments));
                    }
                }
            });
    }
    const deleteComment = (commentId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/brand/deleteComment?token=${token}&commentId=${commentId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = comments.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        comments.splice(ix, 1);
                        setComments(Array.from(comments));
                    }
                }
            });
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getUnApprovedComments?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setComments(response.data.comments)
                }
            });
    }, []);

    return (
        <Box m="20px 0 20px 20px">
            <Box display="flex" gap="20px" mb="20px">
                <Header title="Yorumlar" subtitle="Tüm markalara gelen henüz onaylanmamış yorumlar" />
            </Box>
            <Box m="10px 0 0 0">
                {typeof comments !== "string" && comments.map((comment) =>
                (
                    <Box key={comment.id + "comment"}>
                        <CommentBox comment={comment} isEditable={true} approveComment={approveComment} deleteComment={deleteComment} />
                    </Box>
                )
                )}

            </Box>
        </Box>
    )
}

export default Comments;