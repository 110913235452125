import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import Sidebar from '../../global/Sidebar';
import Topbar from '../../global/Topbar';
import Brands from './brands';
import BrandDetails from './brands/brandDetails';
import Ads from './ads';
import AdGroups from './adGroups';
import Users from './users';
import Influencers from './influencers';
import Partners from './partners';
import Admins from './admins';
import Dashboard from './dashboard';
import SignupSettings from './signupSettings';
import UserDetails from './users/userDetails';
import ContactMessages from './contactMessages';
import Shortcuts from './shortcuts';
import Announcements from './announcements';
import Orders from './orders';
import UserStatistics from './statistics/userStatistics';
import CouponStatistics from './statistics/couponStatistics';
import EarningStatistics from './statistics/earningStatistics';
import Payments from './payments';
import UserProfitStatistics from './statistics/userProfitStatistics';
import BrandStatistics from './statistics/brandStatistics';
import { UserContext } from '../../../context/UserContext';
import InfluencerPartners from './influencerPartners';
import AdWatchStatistics from './statistics/adWatchStatistics';
import CouponDetails from './couponDetails';
import Questions from './questions';
import BrandsTable from './statistics/brandsTable';
import AdditionalApCampaigns from './additionalApCampaigns';
import Comments from './comments';
import OrderComments from './orderComments';
import GeneratedReports from './generatedReports';
import RefCodes from './refCodes';

function AdminPanel() {
    const { user } = useContext(UserContext);

    return (
        <div className="app">
            <Sidebar />
            <main className="content" style={{ marginLeft: "100px" }} >
                <Topbar />
                <Routes>
                    {user.dashboard_perm === 1 && <Route exact path="/" element={<Dashboard />} />}
                    {user.statistics_perm === 1 && <Route exact path="/statistics/users" element={<UserStatistics />} />}
                    {user.statistics_perm === 1 && <Route exact path="/statistics/coupons" element={<CouponStatistics />} />}
                    {user.statistics_perm === 1 && <Route exact path="/statistics/earnings" element={<EarningStatistics />} />}
                    {user.statistics_perm === 1 && <Route exact path="/statistics/user-profits" element={<UserProfitStatistics />} />}
                    {user.statistics_perm === 1 && <Route exact path="/statistics/brands" element={<BrandStatistics />} />}
                    {user.statistics_perm === 1 && <Route exact path="/statistics/ad-watchs" element={<AdWatchStatistics />} />}
                    {user.statistics_perm === 1 && <Route exact path="/statistics/brands-table" element={<BrandsTable />} />}
                    {user.statistics_perm === 1 && <Route exact path="/reports" element={<GeneratedReports />} />}
                    {user.brands_perm === 1 && <Route exact path="/brands" element={<Brands />} />}
                    {user.partners_perm === 1 && <Route exact path="/brands/:partnerId/:partnerName" element={<Brands />} />}
                    {user.brands_perm === 1 && <Route exact path="/brand/:id" element={<BrandDetails />} />}
                    {user.brands_perm === 1 && <Route exact path="/comments" element={<Comments />} />}
                    {user.brands_perm === 1 && <Route exact path="/order-comments" element={<OrderComments />} />}
                    {user.ads_perm === 1 && <Route exact path="/ads" element={<Ads />} />}
                    {user.ads_perm === 1 && <Route exact path="/additional-ap-campaigns" element={<AdditionalApCampaigns />} />}
                    {user.ads_perm === 1 && <Route exact path="/questions" element={<Questions />} />}
                    {user.shortcuts_perm === 1 && <Route exact path="/shortcuts" element={<Shortcuts />} />}
                    {user.sign_up_settings_perm === 1 && <Route exact path="/signup-settings" element={<SignupSettings />} />}
                    {user.users_perm === 1 && <Route exact path="/users" element={<Users />} />}
                    {user.influencers_perm === 1 && <Route exact path="/users/:influencerId/:influencerName" element={<Users />} />}
                    {user.influencers_perm === 1 && <Route exact path="/users/br/:influencerBrandId/:influencerBrandName" element={<Users />} />}
                    {user.users_perm === 1 && <Route exact path="/user/:id" element={<UserDetails />} />}
                    {user.ads_perm === 1 && <Route exact path="/coupon-details/:adId" element={<CouponDetails />} />}
                    {user.users_perm === 1 && <Route exact path="/coupon-details/:userId/:adId" element={<CouponDetails />} />}
                    {user.orders_perm === 1 && <Route exact path="/orders" element={<Orders />} />}
                    {user.contact_messages_perm === 1 && <Route exact path="/contact-messages" element={<ContactMessages />} />}
                    {user.influencers_perm === 1 && <Route exact path="/influencers" element={<Influencers />} />}
                    {user.influencers_perm === 1 && user.brands_perm === 1 && user.ads_perm === 1 && <Route exact path='/ref-codes' element={<RefCodes />} />}
                    {user.partners_perm === 1 && <Route exact path="/partners" element={<Partners />} />}
                    {user.partners_perm === 1 && <Route exact path="/influencer-partners" element={<InfluencerPartners />} />}
                    {user.admins_perm === 1 && <Route exact path="/admins" element={<Admins />} />}
                    {user.announcements_perm === 1 && <Route exact path="/announcements" element={<Announcements />} />}
                    {user.payments_perm === 1 && <Route exact path="/payments" element={<Payments />} />}
                </Routes>
            </main>
        </div>
    )
}

export default AdminPanel;