import { CircularProgress, Dialog, DialogContent } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const ProgressDialog = ({ open, progress }) => {
    return (
        <Dialog
            maxWidth="xs"
            open={open}
        >
            <DialogContent>
                <Box width="50px" height="50px" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color='success' variant={progress === -1 ? "indeterminate" : "determinate"} value={progress} />
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ProgressDialog