import { Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';

function IndependentCouponBox({ independentCoupon, onEditClick, onDeleteClick }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);

    return (
        <Box m="10px" mb="20px" p="20px"
            width={"30%"}
            display="inline-block"
            sx={{
                background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500],
                borderRadius: "20px"
            }}
        >
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center" >
                    <Box>
                        <img
                            alt="brand-logo"
                            src={independentCoupon.brand_logo_link}
                            width="40px"
                            height="40px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />
                    </Box>
                    <Box ml="10px">
                        <Box display="flex" gap="5px">
                            <Typography variant='h4'>{independentCoupon.brand_name}</Typography>
                            <Box padding="1px 10px" sx={{ background: independentCoupon.last_usage_date === null || new Date(independentCoupon.last_usage_date) >= new Date() ? colors.greenAccent[600] : colors.redAccent[600], borderRadius: "10px" }}>
                                <Typography variant='h6'>{independentCoupon.last_usage_date === null ? "Süresiz" : new Date(independentCoupon.last_usage_date) < new Date() ? "Geçmiş" : "Aktif"}</Typography>
                            </Box>
                        </Box>
                        <Typography variant='h6' >{independentCoupon.coupon_title}</Typography>
                    </Box>
                </Box>
                <Box display="flex">

                </Box>
            </Box>

            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Box>
                <Typography variant='h6' maxWidth="250px" mt="10px" ml="20px"><b>Kod:</b> <br />{independentCoupon.coupon_code || "-"}</Typography>

                {independentCoupon.brand_website.length >= 30 &&
                    <Tooltip title={independentCoupon.brand_website}>
                        <Typography variant='h6' maxWidth="250px" mt="10px" ml="20px"><b>Websitesi:</b> <br /><a href={independentCoupon.brand_website} target='_blank' rel="noreferrer" style={{ textDecoration: "none", color: colors.primary[100] }}>{independentCoupon.brand_website.slice(0, 30) + "..."}</a></Typography>
                    </Tooltip>
                }
                {independentCoupon.brand_website.length < 30 &&
                    <Typography variant='h6' maxWidth="250px" mt="10px" ml="20px"><b>Websitesi:</b> <br /><a href={independentCoupon.brand_website} target='_blank' rel="noreferrer" style={{ textDecoration: "none", color: colors.primary[100] }}>{independentCoupon.brand_website}</a></Typography>
                }

                {independentCoupon.coupon_description.length >= 80 &&
                    <Tooltip title={independentCoupon.coupon_description}>
                        <Typography variant='h6' maxWidth="250px" mt="10px" ml="20px"><b>Kupon Açıklaması:</b> <br />{independentCoupon.coupon_description.slice(0, 77) + "..."}</Typography>
                    </Tooltip>
                }
                {independentCoupon.coupon_description.length < 80 &&
                    <Typography variant='h6' maxWidth="250px" mt="10px" ml="20px"><b>Kupon Açıklaması:</b> <br />{independentCoupon.coupon_description}</Typography>
                }
                <Typography variant='h6' maxWidth="250px" mt="10px" ml="20px"><b>Son Kullanma Tarihi:</b> <br />{independentCoupon.last_usage_date !== null ? new Date(independentCoupon.last_usage_date).toLocaleDateString() : "Süresiz"}</Typography>

                <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black"), marginTop: "20px" }} />

                <Typography variant='h6' maxWidth="250px" mt="10px" ml="20px"><b>Kod Görüntülenme Sayısı:</b> <span style={{ marginLeft: "10px" }}>{independentCoupon.code_view_count}</span></Typography>
                <Typography variant='h6' maxWidth="250px" mt="10px" ml="20px"><b>Websitesi Butonu Tıklama Sayısı:</b> <span style={{ marginLeft: "10px" }}>{independentCoupon.website_open_count}</span></Typography>
            </Box>

            <Box display={"flex"} justifyContent={"space-evenly"} mt="20px">
                <Button variant='contained' sx={{ background: colors.blueAccent[500] }} onClick={() => onEditClick(independentCoupon)}>Düzenle</Button>
                <Button variant='contained' color="error" onClick={() => onDeleteClick(independentCoupon)}>Sil</Button>
            </Box>
        </Box >
    )
}

export default IndependentCouponBox