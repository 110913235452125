import React from 'react'
import { useContext } from 'react';
import { Box, Button, Checkbox, Dialog, DialogContent, FormControl, MenuItem, InputLabel, ListItemText, Select, TextField, Typography, useTheme, FormControlLabel } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../../../theme';
import { UserContext } from '../../../../../context/UserContext';
import * as yup from "yup";
import { Formik } from "formik";

function AddComparisonDialog({ open, type, handleClose }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.colors);
    const { token } = useContext(UserContext);

    var initialValues = {
        title: "",
        comparisons: [],
        type: type,
        open_for_brands: false,
        open_for_influencers: false,
        open_for_influencer_brands: false,
        open_for_influencer_partner: false,
        open_for_partners: false,
    }

    var shapeObject = {
        title: yup.string().required("zorunlu"),
        comparisons: yup.array().of(yup.string().required("zorunlu"))
            .test({
                message: 'zorunlu',
                test: arr => arr.length > 0,
            }),
    }

    var comparisonTypes = [
        {
            id: 1,
            label: "Cinsiyet",
            value: "users.gender"
        },
        {
            id: 2,
            label: "Yaşanılan Şehir",
            value: "users.city_of_residence"
        },
        {
            id: 4,
            label: "Eğitim Durumu",
            value: "users.education"
        },
        {
            id: 5,
            label: "Meslek",
            value: "users.job"
        },
        {
            id: 6,
            label: "Gelir Durumu",
            value: "users.income_status"
        },
        {
            id: 7,
            label: "Araba",
            value: "users.have_a_car"
        },
        {
            id: 8,
            label: "Tuttuğu Takım",
            value: "users.team"
        },
        {
            id: 9,
            label: "Medeni Hal",
            value: "users.marital_status"
        }
    ]

    const graphSchema = yup.object().shape(shapeObject);

    const handleFormSubmit = async (values) => {
        var newValues = Object.assign({}, values)
        newValues.comparisons = newValues.comparisons.join(", ");
        newValues.open_for_brands = newValues.open_for_brands ? 1 : 0;
        newValues.open_for_influencers = newValues.open_for_influencers ? 1 : 0;
        newValues.open_for_influencer_brands = newValues.open_for_influencer_brands ? 1 : 0;
        newValues.open_for_influencer_partner = newValues.open_for_influencer_partner ? 1 : 0;
        newValues.open_for_partners = newValues.open_for_partners ? 1 : 0;
        await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/graph?token=${token}`, { graph: newValues }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                handleClose();
            }
        });;
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={open}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    Yeni Karşılaştırma Grafiği Ekle
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={graphSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="string"
                                        label="Başlık"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 1", mr: "5px" }}
                                    />
                                    <FormControl variant="filled"
                                        sx={{ gridColumn: "span 1", maxWidth: "200px", mb: "10px", ml: "5px" }}>
                                        <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Karşılaştırma Türü</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label={"Karşılaştırma Türü"}
                                            onBlur={handleBlur}
                                            multiple
                                            onChange={(event) => {
                                                const { target: { value }, } = event;
                                                console.log(event.target);
                                                setFieldValue("comparisons", typeof value === 'string' ? value.split(', ') : value);
                                            }}
                                            name={"comparisons"}
                                            value={values.comparisons}
                                            renderValue={(selected) => selected.map((s) => comparisonTypes.find((ct) => s === ct.value).label).join(', ')}
                                        >
                                            {comparisonTypes.map((cmpType) => (
                                                <MenuItem key={"comparisons" + cmpType.id} value={cmpType.value}>
                                                    <Checkbox checked={values.comparisons.indexOf(cmpType.value) > -1} />
                                                    <ListItemText primary={cmpType.label} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControlLabel
                                        control={<Checkbox checked={values.open_for_brands} />}
                                        label="Marka Panelinde Gösterilsin"
                                        name="open_for_brands"
                                        onChange={handleChange}
                                        sx={{ gridColumn: "span 2" }}
                                    />

                                    <FormControlLabel
                                        control={<Checkbox checked={values.open_for_influencers} />}
                                        label="Catcher Inviter Panelinde Gösterilsin"
                                        name="open_for_influencers"
                                        onChange={handleChange}
                                        sx={{ gridColumn: "span 2" }}
                                    />

                                    <FormControlLabel
                                        control={<Checkbox checked={values.open_for_influencer_brands} />}
                                        label="Catcher Inviter(Marka) Panelinde Gösterilsin"
                                        name="open_for_influencer_brands"
                                        onChange={handleChange}
                                        sx={{ gridColumn: "span 2" }}
                                    />

                                    <FormControlLabel
                                        control={<Checkbox checked={values.open_for_influencer_partner} />}
                                        label="Influencer Inviter Panelinde Gösterilsin"
                                        name="open_for_influencer_partner"
                                        onChange={handleChange}
                                        sx={{ gridColumn: "span 2" }}
                                    />



                                    <FormControlLabel
                                        control={<Checkbox checked={values.open_for_partners} />}
                                        label="Brand Inviter Panelinde Gösterilsin"
                                        name="open_for_partners"
                                        onChange={handleChange}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </Box>

                                <Box display="flex" justifyContent="center" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AddComparisonDialog;