import { Rating, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import OrderCommentBox from '../../../../components/OrderCommentBox';
import Header from '../../../../components/Header';
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import { useEffect } from 'react';

function OrderComments() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user, token } = useContext(UserContext);
    const [orderComments, setOrderComments] = useState([]);

    const approveComment = (commentId) => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/approveOrderComment?token=${token}&commentId=${commentId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = orderComments.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        orderComments[ix].is_approved = 1;
                        setOrderComments(Array.from(orderComments));
                    }
                }
            });
    }
    const deleteComment = (commentId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/brand/deleteOrderComment?token=${token}&commentId=${commentId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = orderComments.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        orderComments.splice(ix, 1);
                        setOrderComments(Array.from(orderComments));
                    }
                }
            });
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getUnApprovedOrderComments?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setOrderComments(response.data.comments)
                }
            });
    }, []);

    return (
        <Box m="20px 0 20px 20px">
            <Box display="flex" gap="20px" mb="20px">
                <Header title="Sipariş Yorumları" subtitle="Tüm markalara gelen henüz onaylanmamış sipariş yorumları" />
            </Box>
            <Box m="10px 0 0 0">
                {typeof orderComments !== "string" && orderComments.map((comment) =>
                (
                    <Box key={comment.id + "comment"}>
                        <OrderCommentBox comment={comment} isEditable={true} approveComment={approveComment} deleteComment={deleteComment} />
                    </Box>
                )
                )}

            </Box>
        </Box>
    )
}

export default OrderComments;