import { useContext, useEffect, useState } from 'react'
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { tokens } from "../../theme";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ForumIcon from '@mui/icons-material/Forum';
import CommentIcon from '@mui/icons-material/Comment';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import CampaignIcon from '@mui/icons-material/Campaign';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import OrderCommentIcon from '@mui/icons-material/AssistantOutlined';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import 'react-pro-sidebar/dist/scss/styles.scss';
import { UserContext } from '../../context/UserContext';

const Item = ({ title, to, icon, selected, setSelected, isCollapsed, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem active={selected === title} style={{ color: colors.grey[100] }} onClick={() => setSelected(title)} icon={icon}>
      <Typography>
        {title}
      </Typography>
      <Link to={to} onClick={() => { if (isCollapsed === false) { setIsCollapsed(true); } }} />
    </MenuItem>
  )
}

function Sidebar() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState("false");
  const [selected, setSelected] = useState("Panel");
  useEffect(() => {
    const selectedTitle = window.location.pathname.split("/").length > 3 ? window.location.pathname.split("/")[2] + "/" + window.location.pathname.split("/")[3] : window.location.pathname.split("/")[2];
    switch (selectedTitle) {
      case "brands":
        setSelected("Markalar");
        break;
      case "brand":
        setSelected("Markalar");
        break;
      case "statistics/users":
        setSelected("Kullanıcı İstatistikleri");
        break;
      case "statistics/favorites":
        setSelected("Takipçi İstatistikleri");
        break;
      case "statistics/coupons":
        setSelected("Kupon İstatistikleri");
        break;
      case "statistics/earnings":
        setSelected("Kazanç İstatistikleri");
        break;
      case "statistics/user-profits":
        setSelected("Kullanıcı Kâr İstatistikleri");
        break;
      case "statistics/brands":
        setSelected("Marka İstatistikleri");
        break;
      case "statistics/brands-table":
        setSelected("Marka Tablosu");
        break;
      case "reports":
        setSelected("Raporlar");
        break;
      case "ads":
        setSelected("Reklamlar");
        break;
      case "ad":
        setSelected("Reklamlar");
        break;
      case "questions":
        setSelected("Sorular");
        break;
      case "signup-settings":
        setSelected("Kayıt Formu Ayarları");
        break;
      case "shortcuts":
        setSelected("Kısayollar");
        break;
      case "users":
        setSelected("Kullanıcılar");
        break;
      case "user":
        setSelected("Kullanıcılar");
        break;
      case "orders":
        setSelected("Siparişler");
        break;
      case "order":
        setSelected("Siparişler");
        break;
      case "withdraws":
        setSelected("Siparişler");
        break;
      case "contact-messages":
        setSelected("İletişim Mesajları");
        break;
      case "influencers":
        setSelected("Influencerlar");
        break;
      case "influencer":
        setSelected("Influencerlar");
        break;
      case "partners":
        setSelected("Partnerler");
        break;
      case "partner":
        setSelected("Partnerler");
        break;
      case "admins":
        setSelected("Yöneticiler");
        break;
      case "comments":
        setSelected("Yorumlar");
        break;
      case "order-comments":
        setSelected("Sipariş Yorumları");
        break;
      case "ref-codes":
        setSelected("Referans Kodları");
        break;
      case "announcements":
        setSelected("Duyurular");
        break;
      case "payments":
        setSelected("Ödemeler");
        break;
      default:
        setSelected("Panel");
    }
  }, [])

  const { user } = useContext(UserContext);
  var nameAndSurname = "";
  if (user.type === "admin" || user.type === "influencer" || user.type === "partner" || user.type === "influencer_partner") {
    nameAndSurname = user.name.charAt(0).toUpperCase() + user.name.slice(1).toLowerCase() + " " + user.surname.charAt(0).toUpperCase() + user.surname.slice(1).toLowerCase();
  } else if (user.type === "brand") {
    nameAndSurname = user.user_name.charAt(0).toUpperCase() + user.user_name.slice(1).toLowerCase() + " " + user.user_surname.charAt(0).toUpperCase() + user.user_surname.slice(1).toLowerCase();
  }

  const [open, setOpen] = useState(false);
  const [openTools, setOpenTools] = useState(false);
  const [openInviters, setOpenInviters] = useState(false);
  const [openComments, setOpenComments] = useState(false);

  useEffect(() => {
    if (isCollapsed) {
      setOpen(false);
      setOpenTools(false);
      setOpenInviters(false);
      setOpenComments(false);
    }
  }, [isCollapsed])


  return (
    <Box position="fixed" height="100vh" zIndex="10" sx={{
      "& .pro-sidebar-inner": {
        background: `${colors.primary[400]} !important`
      },
      "& .pro-icon-wrapper": {
        backgroundColor: "transparent !important"
      },
      "& .pro-inner-item:hover": {
        color: "#868dfb !important"
      },
      "& .pro-menu-item.active": {
        color: "#6870fa !important"
      }
    }}>

      <ProSidebar collapsed={isCollapsed} style={{ minWidth: "90px" }}>
        <Menu>
          {/* PANEL TITLE AND MENU ICON */}
          <MenuItem onClick={() => { setIsCollapsed(!isCollapsed) }}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100]
            }}>
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <Typography variant="h3" color={colors.grey[100]}>
                  ALCAGO
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* USER */}
          {!isCollapsed && (
            <Box mb="25px" >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center">

                <img
                  alt="profile-user"
                  src={user.type === "admin" ? "../../assets/default-user.png" : (user.type === "influencer" ? user.profile_photo_link : user.logo_link)}
                  width="100px"
                  height="100px"
                  style={{ margin: "auto 0", cursor: "pointer", borderRadius: "50%" }} />
              </Box>

              <Box textAlign="center">
                <Typography variant='h3' color={colors.grey[100]} fontWeight="bold" sx={{ margin: "10px 0 0 0" }}>
                  {nameAndSurname}
                </Typography>
                <Typography variant='h5' color={colors.greenAccent[500]}>
                  {(user.type === "admin" || user.type === "influencer" || user.type === "partner") && user.type.charAt(0).toUpperCase() + user.type.slice(1)}
                  {(user.type === "influencer_partner") && "Influencer Partner"}
                  {user.type === "brand" && user.name.toUpperCase()}
                </Typography>
              </Box>
            </Box>
          )}

          {user.type === "admin" &&
            <Box pl={isCollapsed ? undefined : "10%"}>
              {user.dashboard_perm === 1 && <Item title="Panel" to="/admin-panel/" icon={<DashboardOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.statistics_perm === 1 &&
                <SubMenu
                  title={<Typography>İstatistikler</Typography>}
                  style={{ color: (selected.endsWith("İstatistikleri") || selected.endsWith("Tablosu")) ? "#6870fa" : colors.grey[100] }}
                  onClick={() => { if (isCollapsed) { setIsCollapsed(false); } setOpen(!open); }}
                  open={open}
                  icon={<InsertChartOutlinedTwoToneIcon />}>
                  <Item title="Kullanıcı İstatistikleri" to="/admin-panel/statistics/users" icon={<Groups2OutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                  <Item title="Kullanıcı Kâr İstatistikleri" to="/admin-panel/statistics/user-profits" icon={<PriceCheckOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                  <Item title="Marka İstatistikleri" to="/admin-panel/statistics/brands" icon={<AlbumOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                  <Item title="Marka Tablosu" to="/admin-panel/statistics/brands-table" icon={<AlbumOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                  <Item title="Kupon İstatistikleri" to="/admin-panel/statistics/coupons" icon={<CardGiftcardOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                  <Item title="Kazanç İstatistikleri" to="/admin-panel/statistics/earnings" icon={<PaidOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                  <Item title="Reklam İzlenme İstatistikleri" to="/admin-panel/statistics/ad-watchs" icon={<SlideshowOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                </SubMenu>}
              {user.statistics_perm === 1 &&
                <SubMenu
                  title={<Typography>Alcago Araçları</Typography>}
                  style={{ color: (selected.includes("Raporlar")) ? "#6870fa" : colors.grey[100] }}
                  onClick={() => { if (isCollapsed) { setIsCollapsed(false); } setOpenTools(!openTools); }}
                  open={openTools}
                  icon={<DisplaySettingsIcon />}>
                  <Item title="Raporlar" to="/admin-panel/reports" icon={<SummarizeIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                </SubMenu>}
              {user.brands_perm === 1 && <Item title="Markalar" to="/admin-panel/brands" icon={<AlbumOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.ads_perm === 1 && <Item title="Reklamlar" to="/admin-panel/ads" icon={<FeaturedVideoOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.ads_perm === 1 && <Item title="Sorular" to="/admin-panel/questions" icon={<QuizOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.brands_perm === 1 && <SubMenu title={<Typography>Yorumlar</Typography>} style={{
                color: (selected === "Yorumlar" || selected === "Sipariş Yorumları") ? "#6870fa" : colors.grey[100]
              }}
                onClick={() => { if (isCollapsed) { setIsCollapsed(false); } setOpenComments(!openComments); }}
                open={openComments} icon={<CommentIcon />}>
                <Item title="Yorumlar" to="/admin-panel/comments" icon={<CommentIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                <Item title="Sipariş Yorumları" to="/admin-panel/order-comments" icon={<CommentIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              </SubMenu>}
              {user.sign_up_settings_perm === 1 && <Item title="Kayıt Formu Ayaları" to="/admin-panel/signup-settings" icon={<FormatAlignCenterIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.shortcuts_perm === 1 && <Item title="Kısayollar" to="/admin-panel/shortcuts" icon={<ShortcutIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.users_perm === 1 && <Item title="Kullanıcılar" to="/admin-panel/users" icon={<Groups2OutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.orders_perm === 1 && <Item title="Siparişler" to="/admin-panel/orders" icon={<LocalGroceryStoreOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.contact_messages_perm === 1 && <Item title="İletişim Mesajları" to="/admin-panel/contact-messages" icon={<ForumIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.influencers_perm === 1 && <Item title="Influencerlar" to="/admin-panel/influencers" icon={<ConnectWithoutContactOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.influencers_perm === 1 && user.brands_perm === 1 && user.ads_perm === 1 && <Item title="Referans Kodları" to="/admin-panel/ref-codes" icon={<LeakAddIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}

              {user.partners_perm === 1 && <SubMenu title={<Typography>Inviterlar</Typography>} style={{ color: (selected === "Brand Inviter" || selected === "Influencer Inviter") ? "#6870fa" : colors.grey[100] }} onClick={() => { if (isCollapsed) { setIsCollapsed(false); } setOpenInviters(!openInviters); }} open={openInviters} icon={<HandshakeOutlinedIcon />}>
                <Item title="Brand Inviter" to="/admin-panel/partners" icon={<HandshakeOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                <Item title="Influencer Inviter" to="/admin-panel/influencer-partners" icon={<HandshakeOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              </SubMenu>}

              {user.admins_perm === 1 && <Item title="Yöneticiler" to="/admin-panel/admins" icon={<AdminPanelSettingsOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.announcements_perm === 1 && <Item title="Duyurular" to="/admin-panel/announcements" icon={<CampaignIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              {user.payments_perm === 1 && <Item title="Ödemeler" to="/admin-panel/payments" icon={<AccountBalanceOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
            </Box>
          }

          {user.type === "brand" &&
            <Box pl={isCollapsed ? undefined : "10%"}>
              <Item title="Panel" to="/brand-panel/" icon={<DashboardOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <SubMenu
                title={<Typography>İstatistikler</Typography>}
                style={{ color: selected.endsWith("İstatistikleri") ? "#6870fa" : colors.grey[100] }}
                onClick={() => { if (isCollapsed) { setIsCollapsed(false); } setOpen(!open); }}
                open={open}
                icon={<InsertChartOutlinedTwoToneIcon />}>
                <Item title="Takipçi İstatistikleri" to="/brand-panel/statistics/favorites" icon={<AlbumOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                <Item title="Kupon İstatistikleri" to="/brand-panel/statistics/coupons" icon={<CardGiftcardOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                <Item title="Kazanç İstatistikleri" to="/brand-panel/statistics/earnings" icon={<PaidOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              </SubMenu>
              <SubMenu
                title={<Typography>Alcago Araçları</Typography>}
                style={{ color: (selected.includes("Raporlar")) ? "#6870fa" : colors.grey[100] }}
                onClick={() => { if (isCollapsed) { setIsCollapsed(false); } setOpenTools(!openTools); }}
                open={openTools}
                icon={<DisplaySettingsIcon />}>
                <Item title="Raporlar" to="/brand-panel/reports" icon={<SummarizeIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              </SubMenu>
              <Item title="Reklamlar" to="/brand-panel/ads" icon={<FeaturedVideoOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <Item title="Sorular" to="/brand-panel/questions" icon={<QuizOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <Item title="Yorumlar" to="/brand-panel/comments" icon={<CommentIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              {user.show_order_comments === 1 && <Item title="Sipariş Yorumları" to="/brand-panel/order-comments" icon={<OrderCommentIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
              <Item title="Duyurular" to="/brand-panel/announcements" icon={<CampaignIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              {user.is_influencer === 1 && <Item title="Referans Kodları" to="/brand-panel/ref-codes" icon={<LeakAddIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
            </Box>
          }

          {user.type === "influencer" &&
            <Box pl={isCollapsed ? undefined : "10%"}>
              <Item title="Panel" to="/influencer-panel/" icon={<DashboardOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <SubMenu title={<Typography>İstatistikler</Typography>} style={{ color: selected.endsWith("İstatistikleri") ? "#6870fa" : colors.grey[100] }} onClick={() => { if (isCollapsed) { setIsCollapsed(false); } setOpen(!open); }} open={open} icon={<InsertChartOutlinedTwoToneIcon />}>
                <Item title="Kullanıcı İstatistikleri" to="/influencer-panel/statistics/users" icon={<Groups2OutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                <Item title="Kazanç İstatistikleri" to="/influencer-panel/statistics/earnings" icon={<PaidOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              </SubMenu>
              <Item title="Referans Kodları" to="/influencer-panel/ref-codes" icon={<LeakAddIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <Item title="Duyurular" to="/influencer-panel/announcements" icon={<CampaignIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <Item title="Ödemeler" to="/influencer-panel/payments" icon={<AccountBalanceOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
            </Box>
          }

          {user.type === "partner" &&
            <Box pl={isCollapsed ? undefined : "10%"}>
              <Item title="Panel" to="/partner-panel/" icon={<DashboardOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <SubMenu title={<Typography>İstatistikler</Typography>} style={{ color: selected.endsWith("İstatistikleri") ? "#6870fa" : colors.grey[100] }} onClick={() => { if (isCollapsed) { setIsCollapsed(false); } setOpen(!open); }} open={open} icon={<InsertChartOutlinedTwoToneIcon />}>
                <Item title="Kazanç İstatistikleri" to="/partner-panel/statistics/earnings" icon={<PaidOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              </SubMenu>
              <Item title="Referans Kodları" to="/partner-panel/ref-codes" icon={<LeakAddIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <Item title="Duyurular" to="/partner-panel/announcements" icon={<CampaignIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <Item title="Ödemeler" to="/partner-panel/payments" icon={<AccountBalanceOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
            </Box>
          }

          {user.type === "influencer_partner" &&
            <Box pl={isCollapsed ? undefined : "10%"}>
              <Item title="Panel" to="/influencer-partner-panel/" icon={<DashboardOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <SubMenu title={<Typography>İstatistikler</Typography>} style={{ color: selected.endsWith("İstatistikleri") ? "#6870fa" : colors.grey[100] }} onClick={() => { if (isCollapsed) { setIsCollapsed(false); } setOpen(!open); }} open={open} icon={<InsertChartOutlinedTwoToneIcon />}>
                <Item title="Kazanç İstatistikleri" to="/influencer-partner-panel/statistics/earnings" icon={<PaidOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              </SubMenu>
              <Item title="Referans Kodları" to="/influencer-partner-panel/ref-codes" icon={<LeakAddIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <Item title="Duyurular" to="/influencer-partner-panel/announcements" icon={<CampaignIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              <Item title="Ödemeler" to="/influencer-partner-panel/payments" icon={<AccountBalanceOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
            </Box>
          }
        </Menu>
      </ProSidebar>


    </Box>
  )
}

export default Sidebar