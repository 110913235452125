import React from 'react'
import { useEffect, useContext } from 'react';
import { Alert, Box, Button, Dialog, DialogContent, InputLabel, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import * as yup from "yup";
import { Formik, useField, useFormikContext } from "formik";
import { tokens } from '../theme';
import { UserContext } from '../context/UserContext';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../index.css';

const DatePickerField = ({ ...props }) => {
    const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
    const [field] = useField(props);
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={val => {
                setFieldValue(field.name, val);
                setFieldTouched(field.name);
            }}
            className={props.mode}
        />
    );
};


function IndependentCouponDialog({ open, handleClose, handleSubmit, independentCoupon }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.colors);
    const { token } = useContext(UserContext);

    var initialValues = {
        coupon_title: independentCoupon?.coupon_title ? independentCoupon.coupon_title : "",
        coupon_description: independentCoupon?.coupon_description ? independentCoupon.coupon_description : "",
        coupon_code: independentCoupon?.coupon_code ? independentCoupon.coupon_code : "",
        website_url: independentCoupon?.website_url ? independentCoupon.website_url : "",
        last_usage_date: independentCoupon?.last_usage_date ? new Date(independentCoupon.last_usage_date) : null
    }

    var shapeObject = {
        coupon_title: yup.string().required("zorunlu"),
        coupon_description: yup.string().required("zorunlu"),
        coupon_code: yup.string().nullable(),
        website_url: yup.string().url("URL formatında olmalıdır").nullable().when("coupon_code", {
            is: (coupon_code) => !(coupon_code),
            then: yup.string().url("URL formatında olmalıdır").required("Kupon kodu veya kampanya websitesi girilmelidir")
        }),
        last_usage_date: yup.date().nullable()
    }

    const schema = yup.object().shape(shapeObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={open}
            onClose={handleClose}
        >
            <Box m="20px" mb="0" textAlign="center">
                <Typography variant='h3'>
                    {independentCoupon ? independentCoupon.coupon_title : "Yeni Kupon Ekle"}
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={schema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kupon Başlığı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.coupon_title}
                                        name="coupon_title"
                                        error={!!touched.coupon_title && !!errors.coupon_title}
                                        helperText={touched.coupon_title && errors.coupon_title}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kupon Açıklaması"
                                        multiline
                                        minRows={3}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.coupon_description}
                                        name="coupon_description"
                                        error={!!touched.coupon_description && !!errors.coupon_description}
                                        helperText={touched.coupon_description && errors.coupon_description}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kupon Kodu"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.coupon_code}
                                        name="coupon_code"
                                        error={!!touched.coupon_code && !!errors.coupon_code}
                                        helperText={touched.coupon_code && errors.coupon_code}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kampanya Websitesi"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.website_url}
                                        name="website_url"
                                        error={!!touched.website_url && !!errors.website_url}
                                        helperText={touched.website_url && errors.website_url}
                                        sx={{ gridColumn: "span 2" }}
                                    />

                                    <Box gridColumn="span 1">
                                        <InputLabel size='normal' error={!!touched.last_usage_date && !!errors.last_usage_date} htmlFor="last_usage_date">Kupon son kullanma tarihi:</InputLabel>
                                        <DatePickerField name="last_usage_date" mode={theme.palette.mode} />
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="center" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default IndependentCouponDialog