import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react'
import Header from '../../../../components/Header';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../../../theme';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import BubbleBox from '../../../../components/BubbleBox';
import QuestionBox from '../../../../components/QuestionBox';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

function CouponDetails() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const adId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    const userId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 2] === "coupon-details" ? null : window.location.pathname.split("/")[window.location.pathname.split("/").length - 2];

    const [ad, setAd] = useState(null);
    const [bubbles, setBubbles] = useState([]);
    const [adQuestions, setAdQuestions] = useState([]);
    const [userWatches, setUserWatches] = useState([]);
    const [data, setData] = useState(null);


    useEffect(() => {
        fetchAdData();
        getStatistics();
    }, []);

    const fetchAdData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAdDetails?token=${token}&id=${adId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    if (response.data.ad) {
                        setAd(response.data.ad);
                        if (response.data.ad.bubbles) {
                            if (userId) {
                                axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getBubbles?token=${token}&userId=${userId}&adId=${adId}`)
                                    .catch((err) => {
                                        console.log("err: " + err);
                                        setBubbles([]);
                                    })
                                    .then((response) => {
                                        if (response && response.data.success === true && response.data.status === 200) {
                                            if (response.data.bubbles) {
                                                setBubbles(response.data.bubbles);
                                            }
                                        }
                                    });
                            } else {
                                setBubbles(response.data.ad.bubbles);
                            }
                        }
                        if (response.data.ad.questions) {
                            if (userId) {
                                axios.get(`${process.env.REACT_APP_API_URL}api/alcago/answer/getQuestionsWithAnswers?token=${token}&userId=${userId}&adId=${adId}`)
                                    .catch((err) => {
                                        console.log("err: " + err);
                                        setAdQuestions([]);
                                    })
                                    .then((response) => {
                                        if (response && response.data.success === true && response.data.status === 200) {
                                            if (response.data.questions) {
                                                setAdQuestions(response.data.questions);
                                            }
                                        }
                                    });
                            } else {
                                setAdQuestions(response.data.ad.questions);
                            }
                        }
                        if (userId) {
                            axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getWatchData?token=${token}&userId=${userId}&adId=${adId}`)
                                .catch((err) => {
                                    console.log("err: " + err);
                                    setUserWatches([]);
                                })
                                .then((response) => {
                                    if (response && response.data.success === true && response.data.status === 200) {
                                        if (response.data.watchData) {
                                            setUserWatches(response.data.watchData);
                                        }
                                    }
                                });
                        }
                    }
                }
            });
    }

    const getStatistics = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getBubbleTouches?token=${token}&adId=${adId}`)
            .catch((err) => {
                console.log("err: " + err);
                setData({
                    labels: ["x kordinatı", "y kordinatı"],
                    datasets: [
                        {
                            label: 'Patlatılan Baloncuk Pozisyonları',
                            backgroundColor: "#868dfb",
                            borderColor: "#868dfb",
                            data: [{ x: 0, y: 0 }],
                            pointRadius: 10,
                            datalabels: {
                                display: false
                            }
                        }
                    ],
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setData({
                        labels: ["x kordinatı", "y kordinatı"],
                        datasets: [
                            {
                                label: 'Patlatılan Baloncuk',
                                backgroundColor: "rgba(40, 161, 44, 0.35)",
                                data: response.data.bubbleTouches.filter((bt) => bt.type === 1 && bt.touched === 1),
                                pointRadius: 10,
                                datalabels: {
                                    display: false
                                }
                            },
                            {
                                label: 'Patlatılmayan Baloncuk',
                                backgroundColor: "rgba(186, 22, 22, 0.35)",
                                data: response.data.bubbleTouches.filter((bt) => bt.type === 1 && bt.touched === 0),
                                pointRadius: 10,
                                datalabels: {
                                    display: false
                                }
                            }, {
                                label: 'Patlatılmayan Bomba',
                                backgroundColor: "rgba(40, 161, 44, 0.35)",
                                data: response.data.bubbleTouches.filter((bt) => bt.type === 0 && bt.touched === 0),
                                pointRadius: 10,
                                datalabels: {
                                    display: false
                                }
                            }, {
                                label: 'Patlatılan Bomba',
                                backgroundColor: "rgba(186, 22, 22, 0.35)",
                                data: response.data.bubbleTouches.filter((bt) => bt.type === 0 && bt.touched === 1),
                                pointRadius: 10,
                                datalabels: {
                                    display: false
                                }
                            }
                        ],
                    });

                }
            });
    }

    const getFindFromStr = (key) => {
        const findFroms = {
            wallet_page_featured: "Cüzdan Sayfası - Öne Çıkarılmış Reklamlar",
            main_page_featured: "Ana Sayfa - Öne Çıkarılmış Reklamlar",
            main_page_not_complated: "Ana Sayfa - Yarıda Kalanlar",
            brand_profile_coupons: "Marka Profili - Marka Kuponları",
            all_ads_page: "İnteraktif Reklamlar Sayfası",
            brand_other_coupons: "Reklam Detay Sayfası - Markanın Diğer Kuponları",
            saved_ads_featured: "Kaydedilen Reklamlar Sayfası - Öne Çıkarılmış Reklamlar",
            saved_ads_saveds: "Kaydedilen Reklamlar",
        }

        return findFroms[key];
    }

    const updateAd = () => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/ad/updateAd?token=${token}&adId=${adId}`, { ad: { is_active: ad.is_active === 1 ? 0 : 1 } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setAd({ ...ad, is_active: ad.is_active === 1 ? 0 : 1 });
                }
            });
    }

    return (
        <Box mt="20px">
            {!ad &&
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                </Box>
            }
            {ad &&
                <Box>
                    <Box display="flex">
                        <Box display="flex">
                            <IconButton style={{ width: "40px", height: "40px", marginRight: "5px" }}
                                onClick={() => {
                                    if (userId) {
                                        navigate("/admin-panel/users");
                                    } else {
                                        navigate("/admin-panel/ads");
                                    }
                                }}>
                                <ArrowBackIosNewOutlinedIcon />
                            </IconButton>
                            <Box display="flex" mr="5px">
                                <img
                                    alt="profile-user"
                                    src={ad.thumbnail_image_link}
                                    width="40px"
                                    height="40px"
                                    style={{ cursor: "pointer", borderRadius: "50%" }} />
                            </Box>
                            <Box display="flex" gap={"50px"} ml="10px">
                                <Header title={ad.brand_name} subtitle={ad.title} />
                                {!userId &&
                                    <Button variant='contained' color={ad.is_active === 1 ? 'error' : 'success'} sx={{ margin: "10px 0 auto 0" }} onClick={updateAd}>
                                        {ad.is_active === 1 ? "Reklamı Pasifleştir" : "Reklamı Aktifleştir"}
                                    </Button>
                                }
                            </Box>
                        </Box>

                    </Box>
                    <Box mx="30px" pt="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Yayınlanma Tarihi</b> <hr />{new Date(ad.created_date).toLocaleTimeString("tr-TR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="100px" maxWidth="250px" ml="20px" mb="20px"><b>Kupon Sayısı</b> <hr />{ad.remaining_coupon_count + "/" + ad.starting_coupon_count}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="100px" maxWidth="250px" ml="20px" mb="20px"><b>İzlenme Sayısı</b> <hr />{ad.watch_count}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="100px" maxWidth="250px" ml="20px" mb="20px"><b>Baloncuk Sayısı</b> <hr />{bubbles.length}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="100px" maxWidth="250px" ml="20px" mb="20px"><b>Reklama Özel Soru Sayısı</b> <hr />{adQuestions.length}</Typography>
                        {userId &&
                            <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Kullanıcının İzleme Sayısı</b> <hr />{userWatches.length}</Typography>
                        }
                        {userId && userWatches && userWatches.length > 0 &&
                            <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px">
                                <b>Kullanıcının Kuponu Bulduğu Yer</b>
                                <hr />
                                {userWatches.map((watch) => getFindFromStr(watch.find_from))}
                            </Typography>
                        }
                    </Box>

                    <Box m="30px 20px 0 20px" pb="30px">
                        <Accordion disabled={bubbles.length < 1}>
                            <AccordionSummary id='coupon-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Baloncuklar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0">
                                    <Box>
                                        {bubbles.map((bubble) => <BubbleBox key={bubble.id + "bubble"} bubble={bubble} />)}
                                    </Box>
                                    <Box>
                                        <Bubble options={{
                                            responsive: true,
                                            scales: {
                                                y: {
                                                    ticks: { color: colors.grey[400], stepSize: 100 },
                                                    grid: {
                                                        color: colors.grey[400]
                                                    },
                                                    beginAtZero: true, max: ad && ad.vertical === 1 ? 1920 : 1080
                                                },
                                                x: {
                                                    ticks: { color: colors.grey[400], stepSize: 100 },
                                                    grid: {
                                                        color: colors.grey[400]
                                                    },
                                                    beginAtZero: true, max: ad && ad.vertical === 1 ? 1080 : 1920
                                                }
                                            }
                                        }} data={data} />
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={adQuestions.length < 1}>
                            <AccordionSummary id='coupon-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Reklam Soruları</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0" maxHeight="500px">
                                    {adQuestions.map((adQuestion) => <QuestionBox key={adQuestion.id + "adQuestion"} question={adQuestion} />)}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default CouponDetails;