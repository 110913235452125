import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogContent, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import * as yup from "yup";
import { Formik } from "formik";
import { tokens } from '../theme';
import { UserContext } from '../context/UserContext';

function ForgetPasswordDialog({ open, handleClose, email, type }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.colors);
    const { token } = useContext(UserContext);
    const [generatedCode, setGeneratedCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [messageType, setMessageType] = useState("");

    var passwordResetInitialValues = {
        code: "",
        password: "",
        passwordCheck: ""
    }

    var passwordResetShapeObject = {
        code: yup.string().max(6, "Girilen kod en fazla 6 haneli olmalıdır").required("zorunlu"),
        password: yup.string()
            .required('zorunlu')
            .min(8, 'Şifreniz en az 8 karakter olmalıdır')
            .minLowercase(1, 'Şifreniz en az 1 küçük harf içermelidir')
            .minUppercase(1, 'Şifreniz en az 1 büyük harf içermelidir')
            .minNumbers(1, 'Şifreniz en az 1 rakam içermelidir')
            .minSymbols(1, 'Şifreniz en az 1 özel karakter içermelidir'),
        passwordCheck: yup.string()
            .oneOf([yup.ref('password'), null], 'Şifreler uyuşmuyor')
    }

    const passwordResetSchema = yup.object().shape(passwordResetShapeObject);

    const handlePasswordResetFormSubmit = async (values) => {
        //TODO: check code and reset password
        if (generatedCode == values.code) {
            if (type === "brand") {
                axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/changePasswordWithoutVerification`, { email: email, password: values.password }).catch((err) => {
                    console.log("err: " + err);
                    setMessageType("error");
                    setErrorMessage("Şifre değişikliği şuanda yapılamıyor. Lütfen daha sonra tekrar deneyiniz.");
                }).then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        setMessageType("success");
                        setErrorMessage("Şifreniz başarıyla değiştirildi. Lütfen bekleyiniz.");
                        setTimeout(() => {
                            handleClose();
                        }, 1500);
                    } else {
                        setMessageType("error");
                        setErrorMessage("Şifre değişikliği şuanda yapılamıyor. Lütfen daha sonra tekrar deneyiniz.");
                    }
                });
            } else {
                axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/${type}/changePasswordWithoutVerification`, { email: email, password: values.password }).catch((err) => {
                    console.log("err: " + err);
                    setMessageType("error");
                    setErrorMessage("Şifre değişikliği şuanda yapılamıyor. Lütfen daha sonra tekrar deneyiniz.");
                }).then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        setMessageType("success");
                        setErrorMessage("Şifreniz başarıyla değiştirildi. Lütfen bekleyiniz.");
                        setTimeout(() => {
                            handleClose();
                        }, 1500);
                    } else {
                        setMessageType("error");
                        setErrorMessage("Şifre değişikliği şuanda yapılamıyor. Lütfen daha sonra tekrar deneyiniz.");
                    }
                });
            }
        } else {
            setMessageType("error");
            setErrorMessage("Girilen kod hatalı.");
        }

    }

    useEffect(() => {
        if (email && email !== "") {
            axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/forget-password`, { email: email }).catch((err) => {
                console.log("err: " + err);
                setMessageType("error");
                setErrorMessage("Şifre değişikliği şuanda yapılamıyor. Lütfen daha sonra tekrar deneyiniz.");
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201 && response.data.code && response.data.code !== "") {
                    setGeneratedCode(response.data.code);
                } else {
                    setMessageType("error");
                    setErrorMessage("Şifre değişikliği şuanda yapılamıyor. Lütfen daha sonra tekrar deneyiniz.");
                }
            });
        }
    }, [email])


    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={open}>
            <Box m="20px" mb="0" textAlign="center">
                <Typography variant='h3'>
                    Şifre Sıfırlama
                </Typography>
                {errorMessage !== "" && messageType !== "" && <Alert variant="outlined" severity={messageType} sx={{ mb: "10px", mt: "10px" }} onClick={() => { setErrorMessage(""); setMessageType(""); }}>{errorMessage}</Alert>}
            </Box>
            <DialogContent>
                <Box>
                    <Box mt="10px" mb="15px" textAlign="center">
                        E-posta adresinize bir kod gönderdik.<br />Bu kodu kullanarak şifrenizi değiştirebilirsiniz.
                    </Box>
                    <Formik
                        onSubmit={handlePasswordResetFormSubmit}
                        initialValues={passwordResetInitialValues}
                        validationSchema={passwordResetSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Kod"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.code}
                                        name="code"
                                        error={!!touched.code && !!errors.code}
                                        helperText={touched.code && errors.code}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="password"
                                        label="Yeni Şifreniz"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        name="password"
                                        error={!!touched.password && !!errors.password}
                                        helperText={touched.password && errors.password}
                                        sx={{ gridColumn: "span 1" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="password"
                                        label="Yeni Şifreniz (Tekrar)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.passwordCheck}
                                        name="passwordCheck"
                                        error={!!touched.passwordCheck && !!errors.passwordCheck}
                                        helperText={touched.passwordCheck && errors.passwordCheck}
                                        sx={{ gridColumn: "span 1" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="center" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Devam</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ForgetPasswordDialog