import { Button, Typography, useTheme } from '@mui/material'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box } from '@mui/system'
import React from 'react'
import { tokens } from '../theme';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import VideoPlayerWithThumbnail from './VideoPlayerWithThumbnail';

const AdBox = ({ id, discount_percentage, watch_count, last_usage_date, video_link, thumbnail_link, is_active, width = "23%" }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user, token } = useContext(UserContext);
    const [coupons, setCoupons] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/coupon/getCouponsWithAdId?token=${token}&adId=${id}`, {
            headers: {
                "content-type": "application/json"
            },
        })
            .catch((err) => {
                console.log("err: " + err);
                setCoupons([])
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    var tempCoupons = response.data.coupons.map(({ code }) => code);
                    setCoupons(tempCoupons);
                }
            });
    }, []);

    const handleDownloadClick = () => {
        const element = document.createElement('a');
        const file = new Blob([coupons.join('\n')], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `Alcago-%${discount_percentage}-İndirim-Kuponu-Kodları.txt`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return (
        <Box
            width={width}
            display="inline-block"
            m="10px"
            sx={{ background: colors.grey[100], border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box display="flex" justifyContent="end">
                {is_active !== 0 &&
                    <Box display="flex" justifyContent="center" alignItems="center" textAlign="center" position="absolute" p="5px 10px 5px 10px" m="5px" zIndex="10" sx={{ background: colors.greenAccent[700], borderRadius: "20px" }}>
                        <RemoveRedEyeOutlinedIcon sx={{ color: colors.grey[100] }} />
                        <Typography variant='h6' color={colors.grey[100]} ml="5px">{watch_count}</Typography>
                    </Box>
                }
                <VideoPlayerWithThumbnail thumbnailLink={thumbnail_link} videoLink={video_link} />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box ml="20px" pt="10px" pb="10px">
                    <Typography variant='h3' color={colors.primary[900]}>%{discount_percentage} İndirim Kuponu</Typography>
                    <Typography variant='h6' color={colors.primary[700]}>{last_usage_date}</Typography>
                </Box>
                {is_active !== 0 &&
                    <CheckCircleOutlineOutlinedIcon sx={{ color: colors.greenAccent[700], scale: "2", mr: "20px" }} />}
                {is_active !== 1 &&
                    <HighlightOffOutlinedIcon sx={{ color: colors.redAccent[700], scale: "2", mr: "20px" }} />}
            </Box>
            <Box textAlign="center" mb="5px">
                <Button variant='contained' onClick={handleDownloadClick} disabled={coupons.length <= 0}>Kuponları indir</Button>
            </Box>
        </Box>
    )
}

export default AdBox