import { Alert, Box, Button, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useEffect, useState, useContext } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext'
import { tokens } from '../../../../theme'
import axios from 'axios'


function SignupSettings() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const [findableAreas, setFindableAreas] = useState([]);
    const [incomeStatuses, setIncomeStatuses] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [teams, setTeams] = useState([]);
    const [hobbies, setHobbies] = useState([]);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/profileField/getProfileFields`)
            .catch((err) => {
                console.log("err: " + JSON.stringify(err.response.data));
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setFindableAreas(response.data.profileFields.findable_areas);
                    setIncomeStatuses(response.data.profileFields.income_statuses);
                    setPlatforms(response.data.profileFields.platforms);
                    setTeams(response.data.profileFields.teams);
                    setHobbies(response.data.profileFields.hobbies);
                }
            });
    }, []);


    const updateFields = (table, id, value, isNew, position) => {
        if (isNew) {
            axios.post(`${process.env.REACT_APP_API_URL}api/alcago/profileField/addField?token=${token}&table=${table}&value=${value}`)
                .catch((err) => {
                    console.log("err: " + JSON.stringify(err.response.data));
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        if (table === "findable_areas") {
                            findableAreas[position].id = response.data.fieldId;
                            var tempFindableAreas = JSON.stringify(findableAreas);
                            setFindableAreas([]);
                            setFindableAreas(JSON.parse(tempFindableAreas));
                        } else if (table === "teams") {
                            teams[position].id = response.data.fieldId;
                            var tempTeams = JSON.stringify(teams);
                            setTeams([]);
                            setTeams(JSON.parse(tempTeams));
                        } else if (table === "platforms") {
                            platforms[position].id = response.data.fieldId;
                            var tempPlatforms = JSON.stringify(platforms);
                            setPlatforms([]);
                            setPlatforms(JSON.parse(tempPlatforms));
                        } else if (table === "income_statuses") {
                            incomeStatuses[position].id = response.data.fieldId;
                            var tempIncomeStatuses = JSON.stringify(incomeStatuses);
                            setIncomeStatuses([]);
                            setIncomeStatuses(JSON.parse(tempIncomeStatuses));
                        }


                        setSnackbarInfo({ type: "success", message: "Alan başarıyla eklendi." });
                        setSnackbarOpen(true);
                    }
                });
        } else {
            axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/profileField/updateField?token=${token}&table=${table}&id=${id}&value=${value}`)
                .catch((err) => {
                    console.log("err: " + JSON.stringify(err.response.data));
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {


                        setSnackbarInfo({ type: "success", message: "Alan başarıyla güncellendi." });
                        setSnackbarOpen(true);
                    }
                });
        }
    }

    const updateHobbies = async (hobby, isNew, position) => {
        if (isNew) {
            axios.post(`${process.env.REACT_APP_API_URL}api/alcago/profileField/addHobby?token=${token}`,
                {
                    passiveImage: hobby.passive_image,
                    activeImage: hobby.active_image,
                    value: hobby.value
                }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + JSON.stringify(err.response.data));
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        hobbies[position].id = response.data.fieldId;
                        setHobbies(Array.from(hobbies));
                        setSnackbarInfo({ type: "success", message: "Hobi başarıyla eklendi." });
                        setSnackbarOpen(true);
                    }
                });
        } else {
            if (hobby.active_image) {
                await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/profileField/uploadActiveImage?token=${token}&id=${hobby.id}&path=hobbies&table=hobbies`,
                    { activeImage: hobby.active_image }, {
                    headers: {
                        "content-type": "multipart/form-data"
                    }
                });
            }
            if (hobby.passive_image) {
                await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/profileField/uploadPassiveImage?token=${token}&id=${hobby.id}&path=hobbies&table=hobbies`,
                    { passiveImage: hobby.passive_image }, {
                    headers: {
                        "content-type": "multipart/form-data"
                    }
                });
            }
            axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/profileField/updateField?token=${token}&table=hobbies&id=${hobby.id}&value=${hobby.value}`)
                .catch((err) => {
                    console.log("err: " + JSON.stringify(err.response.data));
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {

                        setSnackbarInfo({ type: "success", message: "Hobi başarıyla güncellendi." });
                        setSnackbarOpen(true);
                    }
                });
        }
    }

    const [snackbarOpen, setSnackbarOpen] = useState(true);
    const [snackbarInfo, setSnackbarInfo] = useState({ type: "success", message: "Başarıyla güncellendi." });


    return (
        <Box mt="20px">
            <Snackbar open={snackbarOpen} autoHideDuration={1500} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                    {snackbarInfo.message}
                </Alert>
            </Snackbar>
            <Box ml="20px" display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Kayıt Formu Ayarları" subtitle="Buradan kayıt formundaki cevapları güncelleyebilirsiniz" />
            </Box>
            <Box display="flex" mt="30px" pb="40px">

                <Box p="20px" mr="30px" sx={{ background: colors.primary[400], borderRadius: "10px" }}>
                    <Typography variant='h5' mb="20px">Takımlar</Typography>
                    <Box>
                        {teams.length > 0 && teams.map((team, i) =>
                            <Box key={i + "teams"} display="inline-block">
                                <Box display="flex" mt="15px" ml="15px">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Değer"
                                        onChange={(event) => {
                                            teams[i].value = event.target.value;
                                            setTeams(Array.from(teams));
                                        }}
                                        value={team.value}
                                    />
                                    <Button variant='contained' sx={{ m: "10px", ml: "20px" }} onClick={() => {
                                        if (teams[i] && teams[i].value) {
                                            updateFields("teams", team.id, teams[i].value, !(team.id && team.id > 0), i)
                                        }
                                    }} >
                                        {(team.id && team.id > 0) && <span>Güncelle</span>}
                                        {!(team.id && team.id > 0) && <span>Ekle</span>}
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Box display="flex">
                        <Button variant='contained' color='success' sx={{ m: "20px auto 0 auto" }} onClick={() => {
                            teams.push({ id: -1, value: "" });
                            setTeams(Array.from(teams));
                        }}>
                            <Typography variant='h2'>+</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box display="flex">

                <Box p="20px" mr="30px" sx={{ background: colors.primary[400], borderRadius: "10px" }}>
                    <Typography variant='h5' mb="20px">Hobiler</Typography>
                    <Box display="flex" mt="15px" alignItems="center" gap="60px">
                        <Typography variant='h6'>Aktif</Typography>
                        <Typography variant='h6'>Pasif</Typography>
                    </Box>
                    {hobbies.length > 0 && hobbies.map((hobby, i) =>
                        <Box key={i + "hobbies"} display="inline-block" mx={"20px"}>

                            <Box display="flex" alignItems="center" mb="15px">
                                <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[500], minWidth: "75px", minHeight: "75px" }}>
                                    {
                                        !hobby.active_image && !hobby.active_image_link && !hobby.active_image_link !== "" &&
                                        <Typography variant="h6">Yükle</Typography>
                                    }
                                    {
                                        ((hobby.active_image) || (hobby.active_image_link && hobby.active_image_link !== "")) &&
                                        <img alt='hobby-active' width="50px" src={hobby.active_image ? URL.createObjectURL(hobby.active_image) : hobby.active_image_link} />
                                    }
                                    <input type="file" accept=".png, .jpeg, .jpg" onChange={(event) => {
                                        if (event.target.files.length > 0) {
                                            hobbies[i].active_image = event.target.files[0];
                                            hobbies[i].active_image_link = "";
                                            setHobbies(Array.from(hobbies));
                                        }
                                    }} hidden />
                                </Button>
                                <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.primary[500], minWidth: "75px", minHeight: "75px", marginLeft: "10px" }}>
                                    {
                                        !hobby.passive_image && !hobby.passive_image_link && !hobby.passive_image_link !== "" &&
                                        <Typography variant="h6">Yükle</Typography>
                                    }
                                    {
                                        ((hobby.passive_image) || (hobby.passive_image_link && hobby.passive_image_link !== "")) &&
                                        <img alt='hobby-passive' width="50px" src={hobby.passive_image ? URL.createObjectURL(hobby.passive_image) : hobby.passive_image_link} />
                                    }
                                    <input type="file" accept=".png, .jpeg, .jpg" onChange={(event) => {
                                        if (event.target.files.length > 0) {
                                            hobbies[i].passive_image = event.target.files[0];
                                            hobbies[i].passive_image_link = "";
                                            setHobbies(Array.from(hobbies));
                                        }
                                    }} hidden />
                                </Button>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Değer"
                                    onChange={(event) => {
                                        hobbies[i].value = event.target.value;
                                        setHobbies(Array.from(hobbies));
                                    }}
                                    value={hobby.value}
                                    sx={{ ml: "10px" }}
                                />
                                <Button variant='contained' sx={{ m: "10px", ml: "20px" }} onClick={() => {
                                    if (hobbies[i] &&
                                        (hobbies[i].active_image || (hobbies[i].active_image_link && hobbies[i].active_image_link !== "")) &&
                                        (hobbies[i].passive_image || (hobbies[i].passive_image_link && hobbies[i].passive_image_link !== "")) &&
                                        hobbies[i].value) {
                                        updateHobbies(hobbies[i], !(hobby.id && hobby.id > 0), i)
                                    }
                                }} >
                                    {(hobby.id && hobby.id > 0) && <span>Güncelle</span>}
                                    {!(hobby.id && hobby.id > 0) && <span>Ekle</span>}
                                </Button>
                            </Box>
                        </Box>
                    )}
                    <Box display="flex">
                        <Button variant='contained' color='success' sx={{ m: "20px auto 0 auto" }} onClick={() => {
                            hobbies.push({ id: -1, value: "", active_image_link: "", passive_image_link: "" });
                            setHobbies(Array.from(hobbies));
                        }}>
                            <Typography variant='h2'>+</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" mt="30px" pb="40px">

                <Box p="20px" mr="30px" sx={{ background: colors.primary[400], borderRadius: "10px" }}>
                    <Typography variant='h5' mb="20px">Bizi nereden duydun?</Typography>
                    {findableAreas.length > 0 && findableAreas.map((area, i) =>
                        <Box key={i + "findableAreas"} display="flex" mt="15px">
                            <TextField
                                fullWidth
                                type="text"
                                label="Değer"
                                onChange={(event) => {
                                    findableAreas[i].value = event.target.value;
                                    setFindableAreas(Array.from(findableAreas));
                                }}
                                value={area.value}
                            />
                            <Button variant='contained' sx={{ m: "10px", ml: "20px" }} onClick={() => {
                                if (findableAreas[i] && findableAreas[i].value) {
                                    updateFields("findable_areas", area.id, findableAreas[i].value, !(area.id && area.id > 0), i)
                                }
                            }} >
                                {(area.id && area.id > 0) && <span>Güncelle</span>}
                                {!(area.id && area.id > 0) && <span>Ekle</span>}
                            </Button>
                        </Box>
                    )}
                    <Box display="flex">
                        <Button variant='contained' color='success' sx={{ m: "20px auto 0 auto" }} onClick={() => {
                            findableAreas.push({ id: -1, value: "" });
                            setFindableAreas(Array.from(findableAreas));
                        }}>
                            <Typography variant='h2'>+</Typography>
                        </Button>
                    </Box>
                </Box>
                <Box p="20px" mr="30px" sx={{ background: colors.primary[400], borderRadius: "10px" }}>
                    <Typography variant='h5' mb="20px">Gelir durumu</Typography>
                    {incomeStatuses.length > 0 && incomeStatuses.map((incomeStatus, i) =>
                        <Box key={i + "incomeStatuses"} display="flex" mt="15px">
                            <TextField
                                fullWidth
                                type="text"
                                label="Değer"
                                onChange={(event) => {
                                    incomeStatuses[i].value = event.target.value;
                                    setIncomeStatuses(Array.from(incomeStatuses));
                                }}
                                value={incomeStatus.value}
                            />
                            <Button variant='contained' sx={{ m: "10px", ml: "20px" }} onClick={() => {
                                if (incomeStatuses[i] && incomeStatuses[i].value) {
                                    updateFields("income_statuses", incomeStatus.id, incomeStatuses[i].value, !(incomeStatus.id && incomeStatus.id > 0), i)
                                }
                            }} >
                                {(incomeStatus.id && incomeStatus.id > 0) && <span>Güncelle</span>}
                                {!(incomeStatus.id && incomeStatus.id > 0) && <span>Ekle</span>}
                            </Button>
                        </Box>
                    )}
                    <Box display="flex">
                        <Button variant='contained' color='success' sx={{ m: "20px auto 0 auto" }} onClick={() => {
                            incomeStatuses.push({ id: -1, value: "" });
                            setIncomeStatuses(Array.from(incomeStatuses));
                        }}>
                            <Typography variant='h2'>+</Typography>
                        </Button>
                    </Box>
                </Box>
                <Box p="20px" mr="30px" sx={{ background: colors.primary[400], borderRadius: "10px" }}>
                    <Typography variant='h5' mb="20px">Sosyal Medya Platformları</Typography>
                    {platforms.length > 0 && platforms.map((platform, i) =>
                        <Box key={i + "platforms"} display="flex" mt="15px">
                            <TextField
                                fullWidth
                                type="text"
                                label="Değer"
                                onChange={(event) => {
                                    platforms[i].value = event.target.value;
                                    setPlatforms(Array.from(platforms));
                                }}
                                value={platform.value}
                            />
                            <Button variant='contained' sx={{ m: "10px", ml: "20px" }} onClick={() => {
                                if (platforms[i] && platforms[i].value) {
                                    updateFields("platforms", platform.id, platforms[i].value, !(platform.id && platform.id > 0), i)
                                }
                            }} >
                                {(platform.id && platform.id > 0) && <span>Güncelle</span>}
                                {!(platform.id && platform.id > 0) && <span>Ekle</span>}
                            </Button>
                        </Box>
                    )}
                    <Box display="flex">
                        <Button variant='contained' color='success' sx={{ m: "20px auto 0 auto" }} onClick={() => {
                            platforms.push({ id: -1, value: "" });
                            setPlatforms(Array.from(platforms));
                        }}>
                            <Typography variant='h2'>+</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SignupSettings;