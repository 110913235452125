import { useContext, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { ColorModeContext, tokens } from '../../theme';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import NotificationDialog from './NotificationDialog';

function Topbar() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const { setToken, user } = useContext(UserContext);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const openSettingsMenu = Boolean(anchorEl);
    const handleSettingsMenuButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSettingsMenuClose = () => {
        setAnchorEl(null);
    };

    const [notificationsDialogOpen, setNotificationsDialogOpen] = useState(false);

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {
                user.type === "admin" &&
                <>
                    <Menu
                        id="settings-menu"
                        anchorEl={anchorEl}
                        open={openSettingsMenu}
                        onClose={handleSettingsMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'settings-button',
                        }}
                    >
                        <MenuItem onClick={() => {
                            setNotificationsDialogOpen(true);
                            handleSettingsMenuClose();
                        }}>
                            <Typography color={colors.grey[100]}>Bildirim Gönder</Typography>
                        </MenuItem>
                    </Menu>
                    {notificationsDialogOpen &&
                        <NotificationDialog open={notificationsDialogOpen} handleClose={() => setNotificationsDialogOpen(false)} />
                    }
                </>
            }
            {/* LOGO */}
            <h2>ALCAGO</h2>


            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
                </IconButton>

                <IconButton onClick={() => navigate(`/${user.type}-panel/profile`)}>
                    <PersonOutlinedIcon />
                </IconButton>

                {user.type === "admin" &&
                    <IconButton onClick={handleSettingsMenuButtonClick}>
                        <SettingsOutlinedIcon />
                    </IconButton>
                }

                <IconButton onClick={() => { setToken(null); }}>
                    <LogoutOutlinedIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default Topbar;