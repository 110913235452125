import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './dashboard';
import Sidebar from '../../global/Sidebar';
import Topbar from '../../global/Topbar';
import Ads from './ads';
import Comments from './comments';
import Profile from './profile';
import Announcements from '../../global/Announcements';
import CouponStatistics from './statistics/couponStatistics';
import EarningStatistics from './statistics/earningStatistics';
import FavoriteStatistics from './statistics/favoriteStatistics';
import RefCodes from './refCodes';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import Questions from './questions';
import OrderComments from './orderComments';
import GeneratedReports from './generatedReports';

function BrandPanel() {

    const { user } = useContext(UserContext);

    return (
        <div className="app">
            <Sidebar />

            <main className="content" style={{ marginLeft: "100px" }} >
                <Topbar />
                <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route exact path="/ads" element={<Ads />} />
                    <Route exact path="/reports" element={<GeneratedReports />} />
                    <Route exact path="/questions" element={<Questions />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/comments" element={<Comments />} />
                    {user.show_order_comments === 1 && <Route exact path="/order-comments" element={<OrderComments />} />}
                    <Route exact path="/announcements" element={<Announcements announcementType="brand" />} />
                    <Route exact path="/statistics/coupons" element={<CouponStatistics />} />
                    <Route exact path="/statistics/earnings" element={<EarningStatistics />} />
                    <Route exact path="/statistics/favorites" element={<FavoriteStatistics />} />
                    {user.is_influencer === 1 && <Route exact path="/ref-codes" element={<RefCodes />} />}
                </Routes>
            </main>
        </div>
    )
}

export default BrandPanel;