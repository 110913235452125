import React from 'react'
import { Box, Button, Checkbox, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { Formik } from "formik";
import * as yup from "yup";

function ReportGenerationDialog({ open, handleClose, fromAdminPanel }) {
    var initialValues = {
        title: "",
        ...(fromAdminPanel ?
            {
                is_blurred: false,
                advices: ""
            } :
            {}
        )
    };

    var validationObject = {
        title: yup.string().required("zorunlu"),
        ...(fromAdminPanel ?
            {
                is_blurred: yup.boolean(),
                advices: yup.string()
            } :
            {}
        )
    }

    const reportSchema = yup.object().shape(validationObject);


    const handleFormSubmit = (values) => {
        handleClose(values);
    }

    return (
        <Dialog sx={{ margin: "0 auto" }}
            open={open}
            onClose={() => handleClose(null)}>
            <Box minWidth="300px" m="20px" textAlign="center">
                <Typography variant='h3'>
                    Rapor Oluştur
                </Typography>
            </Box>

            <DialogContent>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={reportSchema}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px">
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Başlık"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name={"title"}
                                    value={values.title}
                                    error={!!touched.title && !!errors.title}
                                    helperText={touched.title && errors.title}
                                    sx={{ gridColumn: `span ${fromAdminPanel ? "1" : "2"}`, marginTop: `{fromAdminPanel ? "20px" : "0px"}` }}
                                />
                                {fromAdminPanel &&
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        padding="1px 10px"
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                        onClick={() => setFieldValue("is_blurred", !values.is_blurred)}>
                                        <Checkbox
                                            checked={values.is_blurred}
                                            onChange={(e) => setFieldValue("is_blurred", e.target.checked)}
                                            name="is_blurred"
                                            color="primary"

                                        />
                                        <Typography variant='h6' sx={{ userSelect: "none", cursor: "pointer" }} onClick={() => setFieldValue("is_blurred", !values.is_blurred)}>Raporu bulanıklaştır</Typography>
                                    </Box>
                                }
                                {fromAdminPanel &&
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        multiline
                                        rows={4}
                                        label="Öneriler"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"advices"}
                                        value={values.advices}
                                        error={!!touched.advices && !!errors.advices}
                                        helperText={touched.advices && errors.advices}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                }
                            </Box>
                            <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                <Button type="submit" color="secondary" variant="contained">
                                    <Typography variant='h5'>Oluştur</Typography>
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog >
    )
}

export default ReportGenerationDialog;