import { Box, Tab, Tabs, useTheme } from '@mui/material'
import React, { useContext, useState, useEffect, useCallback } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext'
import { tokens } from '../../../../theme'
import axios from 'axios'
import TabPanel, { a11yProps } from '../../../global/TabPanel'
import InfiniteScroll from 'react-infinite-scroll-component'
import RefCodeBoxWithOwner from '../../../../components/RefCodeBoxWithOwner'
import BrandSelectionDialog from '../../../../components/reportGeneration/brandSelectionDialog'
import AdSelectionDialog from '../../../../components/reportGeneration/adSelectionDialog'


function RefCodes() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(UserContext);
  const [influencerRefCodes, setInfluencerRefCodes] = useState([]);
  const [influencerRefCodesHasMore, setInfluencerRefCodesHasMore] = useState(true);
  const [brandRefCodes, setBrandRefCodes] = useState([]);
  const [brandRefCodesHasMore, setBrandRefCodesHasMore] = useState(true);
  const [value, setValue] = useState(0);
  const [brandSelectionDialogOpen, setBrandSelectionDialogOpen] = useState(false);
  const [adSelectionDialogOpen, setAdSelectionDialogOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedRefCodeState, setSelectedRefCodeState] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchInfluencerRefCodes = useCallback((token, startIndex) => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencer/getInfluencerRefCodes?token=${token}&influencerId=-1&startIndex=${startIndex}&limit=10`)
      .catch((err) => {
        console.log("err: " + err);
        setInfluencerRefCodesHasMore(false);
      }, [])
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          if (startIndex === 0) {
            setInfluencerRefCodes(response.data.refCodes);
          } else {
            setInfluencerRefCodes((prev) => [...prev, ...response.data.refCodes]);
          }
        }
      });
  }, [])

  const fetchBrandRefCodes = useCallback((token, startIndex) => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandRefCodes?token=${token}&brandId=-1&startIndex=${startIndex}&limit=10`)
      .catch((err) => {
        console.log("err: " + err);
        setBrandRefCodesHasMore(false);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          if (startIndex === 0) {
            setBrandRefCodes(response.data.refCodes);
          } else {
            setBrandRefCodes((prev) => [...prev, ...response.data.refCodes]);
          }
        }
      });
  }, [])

  useEffect(() => {
    if (value === 0) {
      fetchInfluencerRefCodes(token, 0);
    } else if (value === 1) {
      fetchBrandRefCodes(token, 0);
    }
  }, [fetchBrandRefCodes, fetchInfluencerRefCodes, token, value]);

  useEffect(() => {
    if (selectedBrand) {
      setBrandSelectionDialogOpen(false);
      setAdSelectionDialogOpen(true);
    }
  }, [selectedBrand]);


  const handleWebRegistirationPermissionToggle = (refCode, type) => {
    if (refCode.ad_id) {
      updateRefCode(token, { refCode: refCode, type: type }, null);
    } else {
      setSelectedBrand(null);
      setSelectedRefCodeState({
        refCode: refCode,
        type: type
      })
      setBrandSelectionDialogOpen(true);
    }
  }

  const updateRefCode = (token, refCodeState, adId) => {
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/${refCodeState.type === "influencer" ? "influencer" : "brand"}/updateRefCode?token=${token}&refCodeId=${refCodeState.refCode.id}`, {
      refCode: {
        ad_id: adId
      }
    })
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 201) {
          if (refCodeState.type === "influencer") {
            fetchInfluencerRefCodes(token, 0);
          } else if (refCodeState.type === "influencer_brand") {
            fetchBrandRefCodes(token, 0);
          }
          setSelectedBrand(null);
          setSelectedRefCodeState(null);
        }
      });
  }

  return (
    <Box mt="20px">
      <BrandSelectionDialog
        open={brandSelectionDialogOpen}
        handleClose={(brand) => {
          if (brand) {
            setSelectedBrand(brand)
          } else {
            setSelectedBrand(null);
            setBrandSelectionDialogOpen(false);
          }
        }} />
      {
        selectedBrand &&
        <AdSelectionDialog
          open={adSelectionDialogOpen}
          filterForWebRegistration={true}
          brandId={selectedBrand ? selectedBrand.brand_id : null}
          handleClose={(ad) => {
            if (ad) {
              updateRefCode(token, selectedRefCodeState, ad.ad_id);
            } else {
              setSelectedRefCodeState(null);
              setSelectedBrand(null);
              setAdSelectionDialogOpen(false);
            }
          }} />
      }
      <Box ml="20px" display="flex" alignItems="center">
        <Header title="Referans Kodları" subtitle="Bu sayfadan tüm referans kodlarını görüntüleyebilirsiniz" />
      </Box>
      <Box ml="20px" mr="100px">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor={theme.palette.mode === "dark" ? "secondary" : "primary"}
            indicatorColor={theme.palette.mode === "dark" ? "secondary" : "primary"}>
            <Tab label="CACHER INVITERS" {...a11yProps(0)} />
            <Tab label="CACHER INVITERS(Brand)" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box>
          <TabPanel value={value} index={0}>
            <InfiniteScroll
              dataLength={influencerRefCodes.length}
              next={() => fetchInfluencerRefCodes(token, influencerRefCodes.length)}
              hasMore={influencerRefCodesHasMore}
              loader={<h4>Yükleniyor...</h4>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Tüm referans kodlarını görüntülüyorsunuz.</b>
                </p>
              }
            >
              {influencerRefCodes && influencerRefCodes.length > 0 &&
                (influencerRefCodes.map((influencerRefCode) =>
                  <RefCodeBoxWithOwner
                    key={influencerRefCode.id}
                    refCode={influencerRefCode}
                    type="influencer"
                    onToggleWebRegistirationPermissionButtonClick={handleWebRegistirationPermissionToggle}
                  />
                ))}
            </InfiniteScroll>

          </TabPanel>

          <TabPanel value={value} index={1}>
            <InfiniteScroll
              dataLength={brandRefCodes.length}
              next={() => fetchBrandRefCodes(token, brandRefCodes.length)}
              hasMore={brandRefCodesHasMore}
              loader={<h4>Yükleniyor...</h4>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Tüm referans kodlarını görüntülüyorsunuz.</b>
                </p>
              }
            >
              {brandRefCodes && brandRefCodes.length > 0 &&
                (brandRefCodes.map((brandRefCode) =>
                  <RefCodeBoxWithOwner
                    key={brandRefCode.id}
                    refCode={brandRefCode}
                    type="influencer_brand"
                    onToggleWebRegistirationPermissionButtonClick={handleWebRegistirationPermissionToggle}
                  />
                ))}
            </InfiniteScroll>

          </TabPanel>
        </Box>

      </Box>
    </Box>
  )
}

export default RefCodes;