import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { UserProvider } from "./context/UserContext";
import { Route, Routes } from 'react-router-dom';
import AdminLogin from "./scenes/loginPages/adminLogin";
import BrandLogin from "./scenes/loginPages/brandLogin";
import InfluencerLogin from "./scenes/loginPages/influencerLogin";
import PartnerLogin from "./scenes/loginPages/partnerLogin";
import InfluencerPartnerLogin from "./scenes/loginPages/influencerPartnerLogin";
import ReportsPage from "./scenes/ReportsPage";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <UserProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route exact path="/admin-panel/*" element={<AdminLogin />} />
            <Route exact path="/brand-panel/*" element={<BrandLogin />} />
            <Route exact path="/influencer-panel/*" element={<InfluencerLogin />} />
            <Route exact path="/partner-panel/*" element={<PartnerLogin />} />
            <Route exact path="/influencer-partner-panel/*" element={<InfluencerPartnerLogin />} />
            <Route exact path="/reports/:reportUID" element={<ReportsPage />} />
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </UserProvider>
  );
}

export default App;
