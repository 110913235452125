import React, { useEffect } from 'react'
import { Box, Button, Checkbox, Dialog, DialogContent, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../../../theme';
import { useContext, useState } from 'react';
import { UserContext } from '../../../../../context/UserContext';
import * as yup from "yup";
import { Formik, useField, useFormikContext } from "formik";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../../../../index.css';
import ProgressDialog from '../../../../../components/ProgressDialog';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = ['Reklam Ayarları ve İçeriği', 'Soruları Ekleyin', 'Baloncukları Ayarlayın', 'Hedefleme'];

const DatePickerField = ({ ...props }) => {
  const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val);
        setFieldTouched(field.name);
      }}
      className={props.mode}
    />
  );
};

const MultipleSelectionField = ({ values, handleBlur, setFieldValue, label, fetchedValues, fieldName, keyPlaceHolder }) => {
  return (
    <FormControl fullWidth variant="filled"
      sx={{ gridColumn: "span 1" }}>
      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>{label}</InputLabel>
      <Select
        labelId="filter-label"
        label={label}
        onBlur={handleBlur}
        multiple
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          if (value.length === 0) {
            setFieldValue(fieldName, ["Tümü"]);
          } else {
            if (value.indexOf("Tümü") > -1) {
              if (value.length - 1 === value.indexOf("Tümü")) {
                setFieldValue(fieldName, ["Tümü"]);
              } else {
                value.splice(value.indexOf("Tümü"), 1);
                setFieldValue(fieldName, typeof value === 'string' ? value.split(',') : value);
              }
            } else {
              if (value.length === fetchedValues.length) {
                setFieldValue(fieldName, ["Tümü"]);
              } else {
                setFieldValue(fieldName, typeof value === 'string' ? value.split(',') : value);
              }
            }
          }

        }}
        name={fieldName}
        value={values[fieldName]}
        renderValue={(selected) => selected.join(', ')}
      >
        <MenuItem key={keyPlaceHolder + -1} value={"Tümü"}>
          <Checkbox checked={values[fieldName].indexOf("Tümü") > -1} />
          <ListItemText primary={"Tümü"} />
        </MenuItem>
        {fetchedValues.map((fetchedValue) => (
          <MenuItem key={keyPlaceHolder + fetchedValue.id} value={fetchedValue.value}>
            <Checkbox checked={values[fieldName].indexOf(fetchedValue.value) > -1} />
            <ListItemText primary={fetchedValue.value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function AdDialog({ open, handleClose, type, isAdmin, selectedAd }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.colors);
  const { token } = useContext(UserContext);
  const [show, setShow] = useState(true);
  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);

  const [alcagoTargeting, setAlcagoTargeting] = useState(type !== "add" ? selectedAd.alcago_targeting === 1 : false);
  const [randomAppaerTime, setRandomAppaerTime] = useState(false);

  const [sendNotification, setSendNotification] = useState(false);

  const [cities, setCities] = useState([]);
  const [educationStatuses, setEducationStatuses] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [incomeStatuses, setIncomeStatuses] = useState([]);
  const [teams, setTeams] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [targetableQuestions, setTargetableQuestions] = useState([]);

  const [activeStep, setActiveStep] = useState(0);

  const isStepOptional = (step) => {
    return step === 3;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (progress === 100) {
      setProgressDialogOpen(false);
      setActiveStep(0);
      handleClose();
    }
  }, [progress]);

  useEffect(() => {
    getProfileFields();
    getTargetableQuestions();
  }, []);

  const getProfileFields = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/profileField/getProfileFields`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setCities(response.data.profileFields.cities);
          setEducationStatuses(response.data.profileFields.education_statuses);
          setJobs(response.data.profileFields.jobs);
          setIncomeStatuses(response.data.profileFields.income_statuses);
          setTeams(response.data.profileFields.teams);
          setHobbies(response.data.profileFields.hobbies);
        }
      });
  }

  const getTargetableQuestions = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getTargetableQuestions?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setTargetableQuestions(response.data.questions);
        }
      });
  }


  var initialValues;

  if (type !== "edit") {

    initialValues = {
      ...(isAdmin ? {
        ...(selectedAd.has_coupons && selectedAd.has_coupons === 1 ? {
          releated_ad_count: selectedAd.releated_ad_count ? selectedAd.releated_ad_count : 0,
          description: selectedAd.description ? selectedAd.description : ""
        } : {}),
        tolerance_percentage: selectedAd.tolerance_percentage ? selectedAd.tolerance_percentage : 20,
        comment_ap_count: selectedAd.comment_ap_count ? selectedAd.comment_ap_count : 0
      } : {}),

      vertical: selectedAd.vertical ? selectedAd.vertical === 1 : false,
      has_coupons: selectedAd.has_coupons !== undefined && selectedAd.has_coupons !== null ? selectedAd.has_coupons === 1 : true,
      ...(selectedAd.has_coupons && selectedAd.has_coupons === 1 ? {
        discount_percentage: selectedAd.discount_percentage ? selectedAd.discount_percentage : 0,
        starting_coupon_count: selectedAd.starting_coupon_count ? selectedAd.starting_coupon_count : 0,
        last_usage_date: selectedAd.last_usage_date ? new Date(selectedAd.last_usage_date) : "",
        link: selectedAd.link ? selectedAd.link : "",
      } : {}),


      bubble_image_link: selectedAd.bubble_image_link ? selectedAd.bubble_image_link : "",
      thumbnail_image_link: selectedAd.thumbnail_image_link ? selectedAd.thumbnail_image_link : "",
      video_link: selectedAd.video_link ? selectedAd.video_link : "",
      bubble_image: null,
      thumbnail_image: null,
      ad_video: null,

      targeting_age_min: selectedAd.targeting_age_min ? selectedAd.targeting_age_min : 0,
      targeting_age_max: selectedAd.targeting_age_max ? selectedAd.targeting_age_max : 100,
      targeting_gender: selectedAd.targeting_gender ? selectedAd.targeting_gender : "-1",
      targeting_city_of_residence: selectedAd.targeting_city_of_residence ? selectedAd.targeting_city_of_residence.split(",") : ["Tümü"],
      targeting_education: selectedAd.targeting_education ? selectedAd.targeting_education.split(",") : ["Tümü"],
      targeting_job: selectedAd.targeting_job ? selectedAd.targeting_job.split(",") : ["Tümü"],
      targeting_income_status: selectedAd.targeting_income_status ? selectedAd.targeting_income_status.split(",") : ["Tümü"],
      targeting_have_a_car: selectedAd.targeting_have_a_car ? selectedAd.targeting_have_a_car : "-1",
      targeting_team: selectedAd.targeting_team ? selectedAd.targeting_team.split(",") : ["Tümü"],
      targeting_hobbies: selectedAd.targeting_hobbies ? selectedAd.targeting_hobbies.split(",") : ["Tümü"],
      targeting_marital_status: selectedAd.targeting_marital_status ? selectedAd.targeting_marital_status : "-1",

      bubbles: (selectedAd.bubbles && selectedAd.bubbles.length > 0) ? selectedAd.bubbles : [{
        ...(isAdmin ?
          {
            ap_count: 0
          } : {}),
        appaer_time: "00:00",
        show_time: 4,
        type: 1
      }],
      questions: (selectedAd.questions && selectedAd.questions.length > 0) ? selectedAd.questions : [{
        ...(isAdmin ?
          {
            ap_count: 0
          } : {}),
        question: "",
        answer1: "",
        answer2: "",
        answer3: "",
        correct_answer: ""
      }],
      targeting_questions: selectedAd.targeting_questions && selectedAd.targeting_questions !== ",,," ? selectedAd.targeting_questions.split(",,,").slice(1, -1).map((qa) => { return { question: qa.split(" --- ")[0], answer: qa.split(" --- ")[1] } }) : null
    };
  } else {
    initialValues = {
      ...(isAdmin ? {
        ...(selectedAd.has_coupons && selectedAd.has_coupons === 1 ? {
          releated_ad_count: selectedAd.releated_ad_count ? selectedAd.releated_ad_count : 0,
          description: selectedAd.description ? selectedAd.description : ""
        } : {}),
        tolerance_percentage: selectedAd.tolerance_percentage ? selectedAd.tolerance_percentage : 20,
        comment_ap_count: selectedAd.comment_ap_count ? selectedAd.comment_ap_count : 0,
      } : {}),

      ...(selectedAd.has_coupons && selectedAd.has_coupons === 1 ? {
        last_usage_date: selectedAd.last_usage_date ? new Date(selectedAd.last_usage_date) : "",
        link: selectedAd.link ? selectedAd.link : "",
      } : {}),

      vertical: selectedAd.vertical ? selectedAd.vertical === 1 : false,
      has_coupons: selectedAd.has_coupons ? selectedAd.has_coupons === 1 : false,

      bubble_image_link: selectedAd.bubble_image_link ? selectedAd.bubble_image_link : "",
      thumbnail_image_link: selectedAd.thumbnail_image_link ? selectedAd.thumbnail_image_link : "",
      video_link: selectedAd.video_link ? selectedAd.video_link : "",
      bubble_image: null,
      thumbnail_image: null,
      ad_video: null,

      targeting_age_min: selectedAd.targeting_age_min ? selectedAd.targeting_age_min : 0,
      targeting_age_max: selectedAd.targeting_age_max ? selectedAd.targeting_age_max : 100,
      targeting_gender: selectedAd.targeting_gender ? selectedAd.targeting_gender : "-1",
      targeting_city_of_residence: selectedAd.targeting_city_of_residence ? selectedAd.targeting_city_of_residence.split(",") : ["Tümü"],
      targeting_education: selectedAd.targeting_education ? selectedAd.targeting_education.split(",") : ["Tümü"],
      targeting_job: selectedAd.targeting_job ? selectedAd.targeting_job.split(",") : ["Tümü"],
      targeting_income_status: selectedAd.targeting_income_status ? selectedAd.targeting_income_status.split(",") : ["Tümü"],
      targeting_have_a_car: selectedAd.targeting_have_a_car ? selectedAd.targeting_have_a_car : "-1",
      targeting_team: selectedAd.targeting_team ? selectedAd.targeting_team.split(",") : ["Tümü"],
      targeting_hobbies: selectedAd.targeting_hobbies ? selectedAd.targeting_hobbies.split(",") : ["Tümü"],
      targeting_marital_status: selectedAd.targeting_marital_status ? selectedAd.targeting_marital_status : "-1",

      bubbles: selectedAd.bubbles,
      questions: selectedAd.questions,
      targeting_questions: selectedAd.targeting_questions && selectedAd.targeting_questions !== ",,," ? selectedAd.targeting_questions.split(",,,").slice(1, -1).map((qa) => { return { question: qa.split(" --- ")[0], answer: qa.split(" --- ")[1] } }) : null
    }

  }

  const [bubbleCount, setBubbleCount] = useState(initialValues.bubbles ? initialValues.bubbles.map((b, i) => i) : [0]);
  const [questionCount, setQuestionCount] = useState(initialValues.questions ? initialValues.questions.map((b, i) => i) : [0]);
  const [targetQuestionCount, setTargetQuestionCount] = useState(initialValues.targeting_questions ? initialValues.targeting_questions.map((b, i) => i) : []);

  var shapeObject = {
    ...(isAdmin ?
      {

        tolerance_percentage: yup.number().min(0, "En az 0 olabilir").max(100, "En fazla 100 olabilir.").when('has_coupons', {
          is: (has_coupons) => has_coupons,
          then: yup.number().min(0, "En az 0 olabilir").max(100, "En fazla 100 olabilir.").required("zorunlu")
        }),
        releated_ad_count: yup.number().min(0, "En az 0 olabilir").max(100, "En fazla 5 olabilir.").when('has_coupons', {
          is: (has_coupons) => has_coupons,
          then: yup.number().min(0, "En az 0 olabilir").max(100, "En fazla 5 olabilir.").required("zorunlu")
        }),
        description: yup.string().when('has_coupons', {
          is: (has_coupons) => has_coupons,
          then: yup.string().required("zorunlu")
        }),

        comment_ap_count: yup.number().min(0, "En az 0 olabilir").required("zorunlu")
      } : {}),
    vertical: yup.boolean().required("zorunlu"),

    last_usage_date: yup.date().min(new Date(), "Bitiş tarihi bugünden eski olamaz").when('has_coupons', {
      is: (has_coupons) => has_coupons,
      then: yup.date().min(new Date(), "Bitiş tarihi bugünden eski olamaz").required("zorunlu")
    }),
    link: yup.string().url("Geçersiz url! (örn: https://yourbrand.com/").when('has_coupons', {
      is: (has_coupons) => has_coupons,
      then: yup.string().url("Geçersiz url! (örn: https://yourbrand.com/").required("zorunlu")
    }),

    bubble_image: yup.mixed().when('bubble_image_link', {
      is: (bubble_image_link) => !bubble_image_link || bubble_image_link === "",
      then: yup.string()
        .required('Field is required')
    }),
    bubble_image_link: yup.string(),
    thumbnail_image: yup.mixed().when('thumbnail_image_link', {
      is: (thumbnail_image_link) => !thumbnail_image_link || thumbnail_image_link === "",
      then: yup.string()
        .required('Field is required')
    }),
    thumbnail_image_link: yup.string(),
    ad_video: yup.mixed().when('video_link', {
      is: (video_link) => !video_link || video_link === "",
      then: yup.string()
        .required('Field is required')
    }),
    video_link: yup.string(),

    targeting_age_min: yup.number().required("zorunlu"),
    targeting_age_max: yup.number().required("zorunlu"),
    targeting_gender: yup.string().required("zorunlu"),
    targeting_marital_status: yup.string().required("zorunlu"),
    targeting_city_of_residence: yup.array().of(yup.string()),
    targeting_education: yup.array().of(yup.string()),
    targeting_job: yup.array().of(yup.string()),
    targeting_income_status: yup.array().of(yup.string()),
    targeting_have_a_car: yup.string().required("zorunlu"),
    targeting_team: yup.array().of(yup.string()),
    targeting_hobbies: yup.array().of(yup.string()),

    bubbles: yup.array().of(
      yup.object({
        ...(isAdmin ? { ap_count: yup.number().min(1, "0'dan yüksek olmalıdır.").required("zorunlu") } : {}),
        ...(randomAppaerTime ? {} : {
          appaer_time: yup.string()
            .test(
              'contains',
              'Lütfen geçerli bir süre girin örnek; 00:10',
              function (item) {
                return item && item.length > 0 && item.includes(":");
              }
            )
            .min(5, 'Lütfen geçerli bir süre girin örnek; 00:10')
            .max(5, 'Lütfen geçerli bir süre girin örnek; 00:10').required("zorunlu")
        }),
        show_time: yup.number().min(4, "En az 4 olabilir.").required("zorunlu"),
        type: yup.number().min(0, "").max(1, "").required("zorunlu")
      })
    ),
    questions: yup.array().of(
      yup.object({
        ...(isAdmin ? { ap_count: yup.number().min(1, "0'dan yüksek olmalıdır.").required("zorunlu") } : {}),
        question: yup.string().required("zorunlu"),
        answer1: yup.string().required("zorunlu"),
        answer2: yup.string().required("zorunlu"),
        answer3: yup.string().required("zorunlu"),
        correct_answer: yup.string().required("zorunlu")
      })
    )
  }


  if (type !== "edit") {
    shapeObject = {
      ...shapeObject,
      has_coupons: yup.boolean().required("zorunlu"),
      discount_percentage: yup.number().min(1, "En az 1 olabilir").max(100, "En fazla 100 olabilir.").when('has_coupons', {
        is: (has_coupons) => has_coupons,
        then: yup.number().min(1, "En az 1 olabilir").max(100, "En fazla 100 olabilir.").required("zorunlu")
      }),
      starting_coupon_count: yup.number().min(1, "En az 1 kupon bulunmalıdır").when('has_coupons', {
        is: (has_coupons) => has_coupons,
        then: yup.number().min(1, "En az 1 olabilir").required("zorunlu")
      }),
    }


  }

  const adSchema = yup.object().shape(shapeObject);

  const handleFormSubmit = async (values) => {
    setProgressDialogOpen(true);
    var tqStr = ",,,";
    if (values.targeting_questions !== null & values.targeting_questions !== undefined) {
      values.targeting_questions.forEach((tq) => {
        tqStr += tq.question + " --- " + tq.answer + ",,,"
      })
    }

    if (type !== "add") {

      values.id = selectedAd.id
      if (values.bubble_image) {
        await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/ad/uploadBubbleImage?token=${token}&adId=${values.id}`, { bubbleImage: values.bubble_image }, {
          headers: {
            "content-type": "multipart/form-data"
          }
        });
      }
      if (values.thumbnail_image) {
        await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/ad/uploadThumbnailImage?token=${token}&adId=${values.id}`, { thumbnailImage: values.thumbnail_image }, {
          headers: {
            "content-type": "multipart/form-data"
          }
        });
      }

      if (values.ad_video) {
        await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/ad/uploadVideo?token=${token}&adId=${values.id}`, { adVideo: values.ad_video }, {
          headers: {
            "content-type": "multipart/form-data"
          },
          onUploadProgress: (progressEvent) => {
            const progressOnUpload = (progressEvent.loaded / progressEvent.total) * 100;
            setTimeout(() => {
              setProgress(progressOnUpload);
            }, 1000);
          }
        });
      } else {
        setTimeout(() => {
        }, 500);
        setTimeout(() => {
          setProgress(66);
        }, 1000);
        setTimeout(() => {
          setProgress(100);
        }, 1500);
      }

      let bubble_ap_count = 0;
      const handledBubbles = []

      values.bubbles.forEach(b => {
        if (b.type === 1) {
          bubble_ap_count += b.ap_count;
        }
        handledBubbles.push({
          ap_count: b.ap_count,
          appaer_time: randomAppaerTime ? 0 : ((parseInt(b.appaer_time.split(":")[0]) * 1000 * 60) + (parseInt(b.appaer_time.split(":")[1]) * 1000)),
          show_time: (b.show_time * 1000),
          type: b.type
        });
      });


      if (selectedAd.description !== values.description && values.description && values.description !== "") {
        await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/coupon/updateCouponDescription?token=${token}&adId=${selectedAd.id}`, { description: values.description })
          .catch((err) => {
            console.log("err: " + err);
          })
          .then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {

            }
          });
      }


      await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/ad/updateAdDetails?token=${token}&adId=${values.id}`, {
        ad: {
          ...(
            values.has_coupons ?
              {
                last_usage_date: (values.last_usage_date.toISOString().split("T")[0] + " " + values.last_usage_date.toISOString().split("T")[1]).split("Z")[0]
              }
              :
              {}
          ),
          comment_ap_count: values.comment_ap_count,
          tolerance_percentage: values.tolerance_percentage,
          releated_ad_count: values.releated_ad_count,
          link: values.link,
          bubble_ap_count: bubble_ap_count,
          is_active: 1,
          vertical: values.vertical ? 1 : 0,
          targeting_age_min: values.targeting_age_min && !alcagoTargeting ? values.targeting_age_min : 0,
          targeting_age_max: values.targeting_age_max && !alcagoTargeting ? values.targeting_age_min : 100,
          targeting_gender: values.targeting_gender === "-1" || alcagoTargeting ? null : values.targeting_gender,
          targeting_marital_status: values.targeting_marital_status === "-1" || alcagoTargeting ? null : values.targeting_marital_status,
          targeting_city_of_residence: (values.targeting_city_of_residence &&
            values.targeting_city_of_residence.length === 1 &&
            values.targeting_city_of_residence[0] === "Tümü") || alcagoTargeting ? null : values.targeting_city_of_residence.join(),
          targeting_education: (values.targeting_education &&
            values.targeting_education.length === 1 &&
            values.targeting_education[0] === "Tümü") || alcagoTargeting ? null : values.targeting_education.join(),
          targeting_job: (values.targeting_job &&
            values.targeting_job.length === 1 &&
            values.targeting_job[0] === "Tümü") || alcagoTargeting ? null : values.targeting_job.join(),
          targeting_income_status: (values.targeting_income_status &&
            values.targeting_income_status.length === 1 &&
            values.targeting_income_status[0] === "Tümü") || alcagoTargeting ? null : values.targeting_income_status.join(),
          targeting_have_a_car: values.targeting_have_a_car === "-1" || alcagoTargeting ? null : values.targeting_have_a_car,
          targeting_team: (values.targeting_team &&
            values.targeting_team.length === 1 &&
            values.targeting_team[0] === "Tümü") || alcagoTargeting ? null : values.targeting_team.join(),
          targeting_hobbies: (values.targeting_hobbies &&
            values.targeting_hobbies.length === 1 &&
            values.targeting_hobbies[0] === "Tümü") || alcagoTargeting ? null : values.targeting_hobbies.join(),
          targeting_questions: alcagoTargeting ? "" : tqStr,

          alcago_targeting: alcagoTargeting ? 1 : 0,
        },
        bubbles: handledBubbles,
        questions: values.questions,
        randomAppaerTime: randomAppaerTime
      }).then((response) => {
        if (response && response.data.success === true && response.data.status === 201) {
          if (type === "approve" && sendNotification && selectedAd?.has_coupons === 1) {
            axios.post(`${process.env.REACT_APP_API_URL}api/alcago/notification/notifyUsers?token=${token}`, {
              notification: {
                ad_id: values.id,
                content: selectedAd.brand_name + " yeni bir kupon ekledi. Kuponu hemen al!",
                type: "new_ad",
              },
              brandId: selectedAd.brand_id
            }).then((response) => {
              if (response && response.data.success === true && response.data.status === 201) {
              }
            });
          }
        }
      });
    } else {
      let bubble_ap_count = 0;
      const handledBubbles = []

      values.bubbles.forEach(b => {
        if (b.type === 1) {
          bubble_ap_count += b.ap_count ? b.ap_count : 0;
        }
        handledBubbles.push({ ap_count: b.ap_count ? b.ap_count : 0, appaer_time: randomAppaerTime ? 0 : ((parseInt(b.appaer_time.split(":")[0]) * 1000 * 60) + (parseInt(b.appaer_time.split(":")[1]) * 1000)), show_time: (b.show_time * 1000), type: b.type });
      });

      let adModel = {
        brand_id: selectedAd.brand_id,
        bubble_ap_count: bubble_ap_count,

        starting_coupon_count: values.has_coupons ? values.starting_coupon_count : 0,
        remaining_coupon_count: values.has_coupons ? values.starting_coupon_count : 0,
        releated_ad_count: values.has_coupons ? values.releated_ad_count : 0,
        link: values.has_coupons ? values.link : "",
        discount_percentage: values.has_coupons ? values.discount_percentage : 0,
        ...(
          values.has_coupons ?
            {
              last_usage_date: (values.last_usage_date.toISOString().split("T")[0] + " " + values.last_usage_date.toISOString().split("T")[1]).split("Z")[0]
            }
            :
            {}
        ),

        tolerance_percentage: values.tolerance_percentage,
        comment_ap_count: values.comment_ap_count,
        vertical: values.vertical ? 1 : 0,
        has_coupons: values.has_coupons ? 1 : 0,

        targeting_age_min: values.targeting_age_min && !alcagoTargeting ? values.targeting_age_min : 0,
        targeting_age_max: values.targeting_age_max && !alcagoTargeting ? values.targeting_age_min : 100,
        targeting_gender: values.targeting_gender === "-1" || alcagoTargeting ? null : values.targeting_gender,
        targeting_marital_status: values.targeting_marital_status === "-1" || alcagoTargeting ? null : values.targeting_marital_status,

        targeting_city_of_residence: (values.targeting_city_of_residence &&
          values.targeting_city_of_residence.length === 1 &&
          values.targeting_city_of_residence[0] === "Tümü") || alcagoTargeting ? null : values.targeting_city_of_residence.join(),
        targeting_education: (values.targeting_education &&
          values.targeting_education.length === 1 &&
          values.targeting_education[0] === "Tümü") || alcagoTargeting ? null : values.targeting_education.join(),
        targeting_job: (values.targeting_job &&
          values.targeting_job.length === 1 &&
          values.targeting_job[0] === "Tümü") || alcagoTargeting ? null : values.targeting_job.join(),
        targeting_income_status: (values.targeting_income_status &&
          values.targeting_income_status.length === 1 &&
          values.targeting_income_status[0] === "Tümü") || alcagoTargeting ? null : values.targeting_income_status.join(),
        targeting_have_a_car: values.targeting_have_a_car === "-1" || alcagoTargeting ? null : values.targeting_have_a_car,
        targeting_team: (values.targeting_team &&
          values.targeting_team.length === 1 &&
          values.targeting_team[0] === "Tümü") || alcagoTargeting ? null : values.targeting_team.join(),
        targeting_hobbies: (values.targeting_hobbies &&
          values.targeting_hobbies.length === 1 &&
          values.targeting_hobbies[0] === "Tümü") || alcagoTargeting ? null : values.targeting_hobbies.join(),
        targeting_questions: alcagoTargeting ? "" : tqStr,

        alcago_targeting: alcagoTargeting ? 1 : 0
      }

      if (selectedAd.is_active === 1) {
        adModel.is_active = 1;
      }

      axios.post(`${process.env.REACT_APP_API_URL}api/alcago/ad/?token=${token}`, {
        adVideo: values.ad_video,
        thumbnailImage: values.thumbnail_image,
        bubbleImage: values.bubble_image,
        ad: adModel,
        bubbles: handledBubbles,
        questions: values.questions,
        randomAppaerTime: randomAppaerTime
      }, {
        headers: {
          "content-type": "multipart/form-data"
        },
        onUploadProgress: (progressEvent) => {
          const progressOnUpload = (progressEvent.loaded / progressEvent.total) * 100;
          setTimeout(() => {
            setProgress(progressOnUpload);
          }, 1000);
          console.log(progressEvent);
        }
      })
        .catch((err) => {
          console.log("err: " + err);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            if (values.has_coupons) {
              var couponData = {
                ad_id: response.data.adId,
                brand_id: selectedAd.brand_id,
                title: "%" + values.discount_percentage + " İndirim Kuponu",
                description: values.description ? values.description : ""
              }
              axios.post(`${process.env.REACT_APP_API_URL}api/alcago/coupon/createCoupons?token=${token}&brandName=${selectedAd.brand_name}&brandId=${selectedAd.brand_id}&coupon_count=${values.starting_coupon_count}`, couponData)
                .catch((err) => {
                  console.log("err: " + err);
                })
                .then((response) => {
                  if (response && response.data.success === true && response.data.status === 201) {

                  }
                });
            }
          }
        });
    }

  }

  return (<>
    <Dialog
      sx={{ margin: "0 auto" }} open={open}
      fullWidth
      maxWidth="md"
      onClose={() => {
        setBubbleCount([0]);
        setQuestionCount([0]);
        setTargetQuestionCount([0]);
        setActiveStep(0);
        handleClose();
      }}>
      <Box m="20px" textAlign="center">
        <Typography variant='h3'>
          {type === "edit" ? "Reklamı Düzenle" : (type === "approve" ? "Reklamı Onayla" : "Yeni Reklam Oluştur")}
        </Typography>
      </Box>
      <DialogContent>


        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">İsteğe Bağlı</Typography>
                );
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={adSchema}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>


                <React.Fragment>

                  {/* steps start */}

                  {activeStep === 0 &&
                    <Box>
                      <Box display="flex" justifyContent="space-around" alignItems="center" my={"50px"}>
                        <Box textAlign="center">
                          <Typography variant="h6">Video <br />{values.vertical ? "(1080 x 1920) (9:16)" : "(1920 x 1080) (16:9)"}</Typography>
                          {(!values.ad_video && (!values.video_link || values.video_link === "")) &&
                            <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[500], height: "200px", width: "200px" }}>
                              Yükle
                              <input id="ad_video" name="ad_video" type="file" accept=".mp4" onChange={(event) => { setFieldValue("ad_video", event.target.files[0]); }} hidden />
                            </Button>
                          }
                          {(show && (values.ad_video || (values.video_link && values.video_link !== ""))) &&
                            <video width={values.vertical ? "" : "300px"} height={values.vertical ? "300px" : ""} controls style={{ borderRadius: "10px" }}>
                              <source type="video/mp4" src={values.ad_video ? URL.createObjectURL(values.ad_video) : values.video_link} />
                            </video>}
                          <Box>
                            {(values.ad_video || (values.video_link && values.video_link !== "")) &&
                              <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[500], gridColumn: "span 4", width: "33%", margin: "0 auto 20px auto" }}>
                                Değiştir
                                <input id="ad_video" name="ad_video" type="file" accept=".mp4" onClick={(event) => { setShow(false); }} onChange={(event) => { setFieldValue("ad_video", event.target.files[0]); setFieldValue("video_link", ""); setShow(true); }} hidden />
                              </Button>}
                          </Box>
                        </Box>
                        <Box textAlign="center">
                          <Typography variant="h6">Video Görseli<br />(1080 x 800)</Typography>
                          <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[500], height: "200px", width: "200px" }}>
                            {!values.thumbnail_image && !values.thumbnail_image_link && !values.thumbnail_image_link !== "" && <Typography variant="h6">Yükle</Typography>}
                            {(values.thumbnail_image || (values.thumbnail_image_link && values.thumbnail_image_link !== "")) && <img alt='square brand logo' width="190px" src={values.thumbnail_image ? URL.createObjectURL(values.thumbnail_image) : values.thumbnail_image_link} />}
                            <input id="thumbnail_image" name="thumbnail_image" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail_image", event.target.files[0]); }} hidden />
                          </Button>
                        </Box>
                      </Box>
                      <Box display="grid" columnGap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" mx="75px" mb="25px">

                        <Box gridColumn="span 1" display="flex" alignItems="center">
                          <Checkbox checked={values.vertical} onChange={(event) => { setFieldValue("vertical", event.target.checked); }} />
                          <Typography variant="h6">Dikey Reklam</Typography>
                        </Box>
                        {type !== "edit" && type !== "approve" &&
                          <Box gridColumn="span 1" display="flex" alignItems="center">
                            <Checkbox checked={values.has_coupons} onChange={(event) => { setFieldValue("has_coupons", event.target.checked); }} />
                            <Typography variant="h6">Kupon Dağıtım Reklamı</Typography>
                          </Box>
                        }

                        {type !== "edit" &&
                          <Box gridColumn="span 3"></Box>
                        }
                        {type !== "edit" && values.has_coupons &&
                          <Box gridColumn="span 1"></Box>
                        }
                        {type !== "edit" && values.has_coupons &&
                          <Box gridColumn="span 1"></Box>
                        }
                        {values.has_coupons &&
                          <InputLabel size='normal' error={!!touched.last_usage_date && !!errors.last_usage_date} htmlFor="last_usage_date">Kupon son kullanma tarihi:</InputLabel>
                        }
                        {type === "edit" &&
                          <Box gridColumn="span 1"></Box>
                        }
                        {type === "edit" && !values.has_coupons &&
                          <Box gridColumn="span 1"></Box>
                        }



                        {type !== "edit" && values.has_coupons &&
                          <Box gridColumn="span 1">
                            <TextField
                              fullWidth
                              variant="filled"
                              type="number"
                              label="İndirim oranı(Yüzde)"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.discount_percentage}
                              name="discount_percentage"
                              error={!!touched.discount_percentage && !!errors.discount_percentage}
                              helperText={touched.discount_percentage && errors.discount_percentage}
                            />
                          </Box>
                        }

                        {type !== "edit" && values.has_coupons &&
                          <Box gridColumn="span 1">
                            <TextField
                              fullWidth
                              variant="filled"
                              type="number"
                              label="Kupon sayısı"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.starting_coupon_count}
                              name="starting_coupon_count"
                              error={!!touched.starting_coupon_count && !!errors.starting_coupon_count}
                              helperText={touched.starting_coupon_count && errors.starting_coupon_count}
                            />
                          </Box>
                        }

                        {values.has_coupons &&
                          <Box gridColumn="span 1">
                            <DatePickerField name="last_usage_date" mode={theme.palette.mode} />
                          </Box>
                        }

                        {values.has_coupons &&
                          <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Link"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.link}
                            name="link"
                            error={!!touched.link && !!errors.link}
                            helperText={touched.link && errors.link}
                            sx={type !== "edit" ? { gridColumn: "span 1", mt: "15px" } : {}}
                          />
                        }


                        {isAdmin &&
                          <TextField
                            fullWidth
                            variant="filled"
                            type="number"
                            label="Değerlendirme AP Kazancı"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.comment_ap_count}
                            name="comment_ap_count"
                            error={!!touched.comment_ap_count && !!errors.comment_ap_count}
                            helperText={touched.comment_ap_count && errors.comment_ap_count}
                            sx={{ gridColumn: "span 1", ...(!(type === "edit" && values.has_coupons) ? { mt: "15px" } : {}) }}
                          />
                        }

                        {isAdmin &&
                          <TextField
                            fullWidth
                            variant="filled"
                            type="number"
                            label="Hata Payı(Yüzde)"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.tolerance_percentage}
                            name="tolerance_percentage"
                            error={!!touched.tolerance_percentage && !!errors.tolerance_percentage}
                            helperText={touched.tolerance_percentage && errors.tolerance_percentage}
                            sx={{ gridColumn: "span 1", mt: "15px" }}
                          />
                        }


                        {isAdmin && values.has_coupons &&
                          <TextField
                            fullWidth
                            variant="filled"
                            type="number"
                            label="Kupon İçin İzlenmesi Gereken Reklam Sayısı"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.releated_ad_count}
                            name="releated_ad_count"
                            error={!!touched.releated_ad_count && !!errors.releated_ad_count}
                            helperText={touched.releated_ad_count && errors.releated_ad_count}
                            sx={{ gridColumn: "span 1", mt: "15px" }}
                          />
                        }


                        {isAdmin && values.has_coupons &&
                          <TextField
                            fullWidth
                            multiline
                            minRows="3"
                            variant="filled"
                            type="text"
                            label="Bilgilendirme Yazısı"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            name="description"
                            error={!!touched.description && !!errors.description}
                            helperText={touched.description && errors.description}
                            sx={{ gridColumn: "span 3", mt: "15px" }}
                          />
                        }

                      </Box>
                    </Box>

                  }


                  {activeStep === 1 &&
                    <Box>
                      <Typography variant='h5' mt="50px" ml="75px">Reklam ile ilgili sorular</Typography>
                      {questionCount.map((q, i) =>
                        <Box key={"question" + i} display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" mx="75px" mb="25px">
                          <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Soru"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name={"questions[" + i + "].question"}
                            value={values.questions[i].question}
                            error={!!touched.questions && !!touched.questions[i] && !!touched.questions[i].question && !!errors.questions && !!errors.questions[i] && !!errors.questions[i].question}
                            helperText={touched.questions && touched.questions[i] && touched.questions[i].question && errors.questions && errors.questions[i] && errors.questions[i].question}
                            sx={{ gridColumn: isAdmin ? "span 2" : "span 3", marginTop: "20px" }}
                          />



                          {isAdmin &&
                            <TextField
                              fullWidth
                              variant="filled"
                              type="number"
                              label="Kazanılacak AP Miktarı"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name={"questions[" + i + "].ap_count"}
                              value={values.questions[i].ap_count}
                              error={!!touched.questions && !!touched.questions[i] && !!touched.questions[i].ap_count && !!errors.questions && !!errors.questions[i] && !!errors.questions[i].ap_count}
                              helperText={touched.questions && touched.questions[i] && touched.questions[i].ap_count && errors.questions && errors.questions[i] && errors.questions[i].ap_count}
                              sx={{ gridColumn: "span 1", marginTop: "20px" }}
                            />
                          }
                          <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="A Şıkkı"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name={"questions[" + i + "].answer1"}
                            value={values.questions[i].answer1}
                            error={!!touched.questions && !!touched.questions[i] && !!touched.questions[i].answer1 && !!errors.questions && !!errors.questions[i] && !!errors.questions[i].answer1}
                            helperText={touched.questions && touched.questions[i] && touched.questions[i].answer1 && errors.questions && errors.questions[i] && errors.questions[i].answer1}
                            sx={{ gridColumn: "span 1" }}
                          />
                          <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="B Şıkkı"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name={"questions[" + i + "].answer2"}
                            value={values.questions[i].answer2}
                            error={!!touched.questions && !!touched.questions[i] && !!touched.questions[i].answer2 && !!errors.questions && !!errors.questions[i] && !!errors.questions[i].answer2}
                            helperText={touched.questions && touched.questions[i] && touched.questions[i].answer2 && errors.questions && errors.questions[i] && errors.questions[i].answer2}
                            sx={{ gridColumn: "span 1" }}
                          />
                          <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="C Şıkkı"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name={"questions[" + i + "].answer3"}
                            value={values.questions[i].answer3}
                            error={!!touched.questions && !!touched.questions[i] && !!touched.questions[i].answer3 && !!errors.questions && !!errors.questions[i] && !!errors.questions[i].answer3}
                            helperText={touched.questions && touched.questions[i] && touched.questions[i].answer3 && errors.questions && errors.questions[i] && errors.questions[i].answer3}
                            sx={{ gridColumn: "span 1" }}
                          />
                          <FormControl fullWidth variant="filled">
                            <InputLabel id="filter-label">Doğru Cevap</InputLabel>
                            <Select
                              labelId="filter-label"
                              label="Doğru Cevap"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name={"questions[" + i + "].correct_answer"}
                              value={values.questions[i].correct_answer}
                              error={!!touched.questions && !!touched.questions[i] && !!touched.questions[i].correct_answer && !!errors.questions && !!errors.questions[i] && !!errors.questions[i].correct_answer}
                              sx={{ gridColumn: "span 1" }}
                            >
                              <MenuItem value={values.questions[i].answer1}>A Şıkkı</MenuItem>
                              <MenuItem value={values.questions[i].answer2}>B Şıkkı</MenuItem>
                              <MenuItem value={values.questions[i].answer3}>C Şıkkı</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                      <Box display="flex" justifyContent="space-evenly">
                        <Button color="success" variant="contained" sx={{ borderRadius: "50px", height: "50px", width: "50px" }} onClick={() => {
                          values.questions.push(isAdmin ? {
                            ap_count: 0,
                            question: "",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            correct_answer: ""
                          } : {
                            question: "",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            correct_answer: ""
                          });
                          setQuestionCount([...questionCount, questionCount[questionCount.length - 1] + 1])
                        }}>
                          <Typography variant='h2'>+</Typography>
                        </Button>

                        <Button color="error" variant="contained" sx={{ borderRadius: "50px", height: "50px", width: "50px" }} onClick={() => {
                          if (values.questions.length > 1) {
                            values.questions = values.questions.slice(0, values.questions.length - 1);
                            setQuestionCount([...questionCount.slice(0, questionCount.length - 1)]);
                          }
                        }}>
                          <Typography variant='h2' marginBottom="5px">-</Typography>
                        </Button>
                      </Box>
                    </Box>
                  }

                  {activeStep === 2 &&
                    <Box>
                      <Box gridColumn="span 2" textAlign="center" mt="50px">
                        <Typography variant="h6">Baloncuk Görseli<br />(500 x 500)</Typography>
                        <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[500], height: "100px", width: "100px" }}>
                          {!values.bubble_image && !values.bubble_image_link && !values.bubble_image_link !== "" && <Typography variant="h6">Yükle</Typography>}
                          {((values.bubble_image) || (values.bubble_image_link && values.bubble_image_link !== "")) && <img alt='square brand logo' height="90px" src={values.bubble_image ? URL.createObjectURL(values.bubble_image) : values.bubble_image_link} />}
                          <input id="bubble_image" name="bubble_image" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("bubble_image", event.target.files[0]); }} hidden />
                        </Button>
                      </Box>
                      <Typography variant='h5' mt="50px" ml="75px">Reklam esnasında ekranda gözükecek baloncuklar</Typography>
                      <Box display="inline-block" ml="65px" mt="20px">
                        <Checkbox
                          checked={randomAppaerTime}
                          size="small"
                          sx={{
                            '&.Mui-checked': {
                              color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200])
                            }
                          }}
                          onClick={(event) => {
                            setRandomAppaerTime((prev) => !prev);
                          }}
                        />
                        <Typography variant='h7'>Gösterim zamanları rastgele olarak ayarlansın.</Typography>
                      </Box>
                      {bubbleCount.map((b, i) =>
                        <Box key={"bubble" + i} display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" mb="25px" mt="20px">
                          {isAdmin &&
                            <TextField
                              fullWidth
                              variant="filled"
                              type="number"
                              label="Kazanılacak AP Miktarı"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name={"bubbles[" + i + "].ap_count"}
                              value={values.bubbles[i].ap_count}
                              error={!!touched.bubbles && !!touched.bubbles[i] && !!touched.bubbles[i].ap_count && !!errors.bubbles && !!errors.bubbles[i] && !!errors.bubbles[i].ap_count}
                              helperText={touched.bubbles && touched.bubbles[i] && touched.bubbles[i].ap_count && errors.bubbles && errors.bubbles[i] && errors.bubbles[i].ap_count}
                              sx={{ gridColumn: "span 1" }}
                            />
                          }
                          {!randomAppaerTime &&
                            <TextField
                              fullWidth
                              variant="filled"
                              type="text"
                              label="Gösterileceği Zaman"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name={"bubbles[" + i + "].appaer_time"}
                              value={values.bubbles[i].appaer_time}
                              sx={{ gridColumn: "span 1" }}
                              error={!!touched.bubbles && !!touched.bubbles[i] && !!touched.bubbles[i].appaer_time && !!errors.bubbles && !!errors.bubbles[i] && !!errors.bubbles[i].appaer_time}
                              helperText={touched.bubbles && touched.bubbles[i] && touched.bubbles[i].appaer_time && errors.bubbles && errors.bubbles[i] && errors.bubbles[i].appaer_time}
                              inputProps={{ maxLength: 5 }}
                            />}
                          <TextField
                            fullWidth
                            variant="filled"
                            type="number"
                            label="Ekranda Kalacağı Süre(saniye)"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name={"bubbles[" + i + "].show_time"}
                            value={values.bubbles[i].show_time}
                            sx={{ gridColumn: "span 1" }}
                            error={!!touched.bubbles && !!touched.bubbles[i] && !!touched.bubbles[i].show_time && !!errors.bubbles && !!errors.bubbles[i] && !!errors.bubbles[i].show_time}
                            helperText={touched.bubbles && touched.bubbles[i] && touched.bubbles[i].show_time && errors.bubbles && errors.bubbles[i] && errors.bubbles[i].show_time}
                          />

                          <FormControl fullWidth variant="filled"
                            sx={{ gridColumn: "span 1" }}>
                            <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Tür</InputLabel>
                            <Select
                              labelId="filter-label"
                              label="Tür"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name={"bubbles[" + i + "].type"}
                              value={values.bubbles[i].type}
                            >
                              <MenuItem value={1}>Puan Kazandıran</MenuItem>
                              <MenuItem value={0}>Puan Düşüren</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      )}

                      <Box display="flex" justifyContent="space-evenly">
                        <Button color="success" variant="contained" sx={{ borderRadius: "50px", height: "50px", width: "50px" }} onClick={() => {
                          values.bubbles.push(isAdmin ? {
                            ap_count: 0,
                            appaer_time: "00:00",
                            show_time: 4,
                            type: 1
                          } : {
                            appaer_time: "00:00",
                            show_time: 4,
                            type: 1
                          });
                          setBubbleCount([...bubbleCount, bubbleCount[bubbleCount.length - 1] + 1])
                        }}>
                          <Typography variant='h2'>+</Typography>
                        </Button>

                        <Button color="error" variant="contained" sx={{ borderRadius: "50px", height: "50px", width: "50px" }} onClick={() => {
                          if (values.bubbles.length > 1) {
                            values.bubbles = values.bubbles.slice(0, values.bubbles.length - 1);
                            setBubbleCount([...bubbleCount.slice(0, bubbleCount.length - 1)]);
                          }
                        }}>
                          <Typography variant='h2' marginBottom="5px">-</Typography>
                        </Button>
                      </Box>
                    </Box>
                  }

                  {activeStep === 3 &&
                    <Box mt="20px" mx="50px">
                      <Box display="inline-block" mr="20px">
                        <Checkbox
                          checked={alcagoTargeting}
                          size="small"
                          sx={{
                            '&.Mui-checked': {
                              color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200])
                            }
                          }}
                          onClick={(event) => {
                            setAlcagoTargeting((prev) => !prev);
                          }}
                        />
                        <Typography variant='h7'>Hedeflemeler Alcago uzmanları tarafından ayarlansın.</Typography>
                      </Box>
                      {alcagoTargeting &&
                        <Box textAlign="center">
                          <Typography variant='h6' my="20px">Hedeflemeler Alcago tarafından markanıza en uygun şekilde ayarlanacak.</Typography>
                        </Box>}

                      {!alcagoTargeting &&
                        <Box my="25px">
                          <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                            <Typography variant='h4' gridColumn="span 4">Demografik Hedeflemeler</Typography>
                            <TextField
                              fullWidth
                              variant="filled"
                              type="text"
                              label="Yaş(Minimum)"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.targeting_age_min}
                              name="targeting_age_min"
                              error={!!touched.targeting_age_min && !!errors.targeting_age_min}
                              helperText={touched.targeting_age_min && errors.targeting_age_min}
                              sx={{ gridColumn: "span 1" }}
                            />

                            <TextField
                              fullWidth
                              variant="filled"
                              type="text"
                              label="Yaş(Maximum)"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.targeting_age_max}
                              name="targeting_age_max"
                              error={!!touched.targeting_age_max && !!errors.targeting_age_max}
                              helperText={touched.targeting_age_max && errors.targeting_age_max}
                              sx={{ gridColumn: "span 1" }}
                            />

                            <FormControl fullWidth variant="filled"
                              sx={{ gridColumn: "span 1" }}>
                              <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Cinsiyet</InputLabel>
                              <Select
                                labelId="filter-label"
                                label="Cinsiyet"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="targeting_gender"
                                value={values.targeting_gender}
                              >
                                <MenuItem value={"-1"}>Tümü</MenuItem>
                                <MenuItem value={"Kadın"}>Kadın</MenuItem>
                                <MenuItem value={"Erkek"}>Erkek</MenuItem>
                              </Select>
                            </FormControl>

                            <FormControl fullWidth variant="filled"
                              sx={{ gridColumn: "span 1" }}>
                              <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Medeni Hal</InputLabel>
                              <Select
                                labelId="filter-label"
                                label="Medeni Hal"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="targeting_marital_status"
                                value={values.targeting_marital_status}
                              >
                                <MenuItem value={"-1"}>Tümü</MenuItem>
                                <MenuItem value={"Evli"}>Evli</MenuItem>
                                <MenuItem value={"Bekar"}>Bekar</MenuItem>
                              </Select>
                            </FormControl>

                            <MultipleSelectionField values={values} handleBlur={handleBlur}
                              setFieldValue={setFieldValue} label={"Yaşadığı Şehir"} fetchedValues={cities}
                              fieldName={"targeting_city_of_residence"} keyPlaceHolder={"cityR"} />

                            <MultipleSelectionField values={values} handleBlur={handleBlur}
                              setFieldValue={setFieldValue} label={"Eğitim Durumu"} fetchedValues={educationStatuses}
                              fieldName={"targeting_education"} keyPlaceHolder={"edu"} />

                            <MultipleSelectionField values={values} handleBlur={handleBlur}
                              setFieldValue={setFieldValue} label={"Meslek"} fetchedValues={jobs}
                              fieldName={"targeting_job"} keyPlaceHolder={"job"} />

                            <MultipleSelectionField values={values} handleBlur={handleBlur}
                              setFieldValue={setFieldValue} label={"Gelir Durumu"} fetchedValues={incomeStatuses}
                              fieldName={"targeting_income_status"} keyPlaceHolder={"incomeStatus"} />

                            <FormControl fullWidth variant="filled"
                              sx={{ gridColumn: "span 1" }}>
                              <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Araba</InputLabel>
                              <Select
                                labelId="filter-label"
                                label="Araba"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="targeting_have_a_car"
                                value={values.targeting_have_a_car}
                              >
                                <MenuItem value={"-1"}>Tümü</MenuItem>
                                <MenuItem value={"Var"}>Var</MenuItem>
                                <MenuItem value={"Yok"}>Yok</MenuItem>
                              </Select>
                            </FormControl>

                            <MultipleSelectionField values={values} handleBlur={handleBlur}
                              setFieldValue={setFieldValue} label={"Tuttuğu Takım"} fetchedValues={teams}
                              fieldName={"targeting_team"} keyPlaceHolder={"team"} />

                            <MultipleSelectionField values={values} handleBlur={handleBlur}
                              setFieldValue={setFieldValue} label={"Hobiler"} fetchedValues={hobbies}
                              fieldName={"targeting_hobbies"} keyPlaceHolder={"hobby"} />

                          </Box >

                          <Box mt="25px" >
                            <Typography variant='h4' mb="20px" >Hedeflenebilir Anket Soruları</Typography>
                            {targetQuestionCount.map((tq, i) =>
                              <Box key={"tq" + i} display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" mb="10px"  >
                                <FormControl
                                  fullWidth
                                  sx={{ gridColumn: "span 2" }}
                                  variant="filled">
                                  <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Soru</InputLabel>
                                  <Select
                                    labelId="filter-label"
                                    label="Soru"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name={"targeting_questions[" + i + "].question"}
                                    value={values.targeting_questions[i].question}
                                  >
                                    {targetableQuestions.map((q) => (
                                      <MenuItem key={"targetableQuestion" + q.id} value={q.question}>{q.question}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {values.targeting_questions[i] && values.targeting_questions[i].question &&
                                  <FormControl
                                    fullWidth
                                    sx={{ gridColumn: "span 1" }}
                                    variant="filled">
                                    <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Cevap</InputLabel>
                                    <Select
                                      labelId="filter-label"
                                      label="Cevap"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      name={"targeting_questions[" + i + "].answer"}
                                      value={values.targeting_questions[i].answer}
                                    >
                                      <MenuItem value={targetableQuestions.find(q => q.question === values.targeting_questions[i].question).answer1}>{targetableQuestions.find(q => q.question === values.targeting_questions[i].question).answer1}</MenuItem>
                                      <MenuItem value={targetableQuestions.find(q => q.question === values.targeting_questions[i].question).answer2}>{targetableQuestions.find(q => q.question === values.targeting_questions[i].question).answer2}</MenuItem>
                                      <MenuItem value={targetableQuestions.find(q => q.question === values.targeting_questions[i].question).answer3}>{targetableQuestions.find(q => q.question === values.targeting_questions[i].question).answer3}</MenuItem>
                                    </Select>
                                  </FormControl>
                                }
                              </Box>
                            )}
                          </Box>
                          <Box textAlign="center" mt="20px">
                            <Button color="primary" size='small' variant="contained" onClick={() => {
                              if (values.targeting_questions === null || values.targeting_questions === undefined) {
                                values.targeting_questions = [];
                              }
                              values.targeting_questions.push({
                                question: "",
                                answer: ""
                              });
                              setTargetQuestionCount((prev) => prev && prev.length > 0 ? [...prev, prev[prev.length - 1] + 1] : [0]);
                            }}>
                              <Typography variant='h6'>Yeni Soru</Typography>
                            </Button>
                          </Box>
                        </Box>
                      }
                    </Box>
                  }

                  {/* steps end */}

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Geri
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep === steps.length - 1 ? (
                      <React.Fragment>

                        {!!(type === "approve" && selectedAd?.has_coupons === 1) &&
                          <Box display="inline-block" mr="20px">
                            <Checkbox
                              checked={sendNotification}
                              size="small"
                              sx={{
                                '&.Mui-checked': {
                                  color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200])
                                }
                              }}
                              onClick={(event) => {
                                setSendNotification((prev) => !prev);
                              }}
                            />
                            <Typography variant='h7'>Markanın takipçilerine bildirim gönder.</Typography>
                          </Box>
                        }
                        {/* submit button */}
                        {type !== "approve" &&
                          <Button type="submit" color="secondary" variant="contained" >
                            <Typography variant='h5'>Kaydet</Typography>
                          </Button>
                        }
                        {type === "approve" &&
                          <Button type="submit" variant="contained" sx={{ background: "#edde4d", color: "black" }} >
                            <Typography variant='h5'>Onayla</Typography>
                          </Button>
                        }
                      </React.Fragment>
                    ) :
                      (<Button color='warning' onClick={handleNext}>
                        İleri
                      </Button>)
                    }

                  </Box>
                </React.Fragment>



              </form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
    <ProgressDialog open={progressDialogOpen} progress={progress} />
  </>
  )
}

export default AdDialog;